import { ServiceType } from "./Services";

export const servicesData: ServiceType[] = [
    {
      headline: "ai.services.items.a.headline",
      text: "ai.services.items.a.text",
      listOfItems: [
        { text: "ai.services.items.a.listOfItems.a.text" },
        { text: "ai.services.items.a.listOfItems.b.text" },
        { text: "ai.services.items.a.listOfItems.c.text" },
        { text: "ai.services.items.a.listOfItems.d.text" },
      ],
    },
    {
      headline: "ai.services.items.b.headline",
      text: "ai.services.items.b.text",
      listOfItems: [
        { text: "ai.services.items.b.listOfItems.a.text" },
        { text: "ai.services.items.b.listOfItems.b.text" },
        { text: "ai.services.items.b.listOfItems.c.text" },
        { text: "ai.services.items.b.listOfItems.d.text" },
      ],
    },
    {
      headline: "ai.services.items.c.headline",
      text: "ai.services.items.c.text",
      listOfItems: [
        { text: "ai.services.items.c.listOfItems.a.text" },
        { text: "ai.services.items.c.listOfItems.b.text" },
        { text: "ai.services.items.c.listOfItems.c.text" },
        { text: "ai.services.items.c.listOfItems.d.text" },
      ],
    },
  ];