import { Button } from "../Components/Button/Button";
import { ButtonOptionType, StepFormAnswerType } from "./StepFormTypes";

export type FormUserActionType = {
  type: "buttonClick";
  actionData: ButtonOptionType;
};

export const useRenderStepAnswer = (
  answer: StepFormAnswerType,
  onFormAction: (action: FormUserActionType) => void
) => {
  switch (answer.type) {
    case "button_options":
      return (
        <div className="flex justify-center gap-5 flex-wrap">
          {answer.answerConfig.options.map((option) => (
            <Button
              label={option.label}
              className="px-2 py-1 border border-dark !bg-transparent rounded-lg hover:border-blue/50 active:text-blue active:border-blue transition-all transition-200 !text-base"
              action={() =>
                onFormAction({ type: "buttonClick", actionData: option })
              }
            />
          ))}
        </div>
      );
    default:
      break;
  }
};
