import { Card } from "../Card/Card";

export type DataFactType = {
  number: number;
  symbol?: string;
  text: string;
  className?: string;
};

export const DataFact = ({ number, symbol = "%", text, className }: DataFactType) => {
  return (
    <Card className={className} >
      <div className="text-4xl md:text-6xl 2xl:text-9xl font-extrabold">
        {number}
        <span className="text-xl md:text-4xl">{symbol}</span>
      </div>
      <h3 className="body-2 mt-auto">{text}</h3>
    </Card>
  );
};
