import "./Button.scss";

type ButtonPropsType = {
  label: string;
  className?: string;
  action: () => void;
};

export const Button = ({ label, className, action }: ButtonPropsType) => {

  return (
    <button
      className={`button px-4 py-2 bg-white text-xl shadow-smooth rounded-full  border border-grey ${className} `}
      onClick={action}
    >
      {label}
    </button>
  );
};
