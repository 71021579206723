export default function CarouselItem({
  text,
  image,
}: {
  text: string;
  image: any;
}) {
  return (
    <div className="carousel-card relative">
      <p className="body-2 z-10 absolute">{text}</p>
      <div className="carousel-card__image opacity-20">{image}</div>
    </div>
  );
}
