export type faqItemType = {
  question: string;
  answer: string;
};

export type faqDataType = {
  headline: string;
  text: string;
  items: faqItemType[];
};

export const aiFaqData: faqDataType = {
  headline: "ai.qa.headline",
  text: "ai.qa.headline",
  items: [
    { question: "ai.qa.items.a.question", answer: "ai.qa.items.a.answer" },
    { question: "ai.qa.items.b.question", answer: "ai.qa.items.b.answer" },
    { question: "ai.qa.items.c.question", answer: "ai.qa.items.c.answer" },
    { question: "ai.qa.items.d.question", answer: "ai.qa.items.d.answer" },
    { question: "ai.qa.items.e.question", answer: "ai.qa.items.e.answer" },
    { question: "ai.qa.items.f.question", answer: "ai.qa.items.f.answer" },
    { question: "ai.qa.items.g.question", answer: "ai.qa.items.g.answer" },
    { question: "ai.qa.items.h.question", answer: "ai.qa.items.h.answer" },
  ],
};
