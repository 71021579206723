export const GermanySvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke={stroke}
      >
        <path
          d="M3047 10193 c-74 -128 -89 -173 -90 -273 -2 -79 1 -95 13 -95 12 0
    16 16 18 72 4 89 15 102 72 83 34 -11 45 -11 77 3 55 23 143 33 143 15 0 -7
    18 -35 40 -63 22 -27 40 -55 40 -62 0 -6 18 -33 40 -60 21 -26 51 -62 65 -79
    14 -18 25 -44 25 -58 0 -22 4 -26 28 -26 17 0 37 -9 49 -22 22 -25 47 -68 41
    -73 -2 -1 -29 -10 -61 -19 -45 -13 -57 -21 -53 -32 3 -9 6 -20 6 -25 0 -5 20
    -9 44 -9 33 0 52 7 81 30 l39 31 13 -32 c16 -40 17 -39 -5 -39 -10 0 -39 -13
    -65 -30 -46 -29 -52 -30 -166 -30 -104 0 -120 -2 -136 -20 -17 -18 -16 -19 11
    -26 42 -10 46 -14 33 -30 -6 -8 -26 -14 -45 -14 -25 0 -34 -5 -40 -22 -5 -18
    2 -29 37 -59 l44 -37 29 21 c17 12 49 22 75 24 25 1 69 4 96 6 28 1 56 7 64
    11 10 7 12 5 6 -5 -18 -31 -38 -48 -64 -54 -21 -5 -29 -16 -43 -58 -16 -50
    -15 -53 5 -82 11 -16 21 -31 22 -33 1 -1 28 1 60 6 l57 9 15 -32 c11 -23 14
    -46 9 -79 -8 -50 -18 -55 -71 -32 -32 13 -75 10 -75 -6 0 -19 50 -94 85 -128
    32 -31 43 -35 88 -36 139 -1 220 -16 286 -52 58 -32 66 -41 92 -98 24 -53 36
    -67 72 -85 50 -26 53 -30 61 -80 5 -32 13 -42 59 -68 41 -24 169 -61 210 -61
    4 0 7 -10 7 -21 0 -18 -4 -20 -27 -15 -16 3 -53 10 -83 15 -30 5 -81 21 -112
    35 -47 21 -61 33 -79 68 -12 24 -50 75 -84 113 -34 39 -74 87 -90 108 -57 77
    -167 107 -265 72 -46 -16 -176 -19 -268 -6 -40 6 -61 15 -87 41 -31 30 -36 32
    -62 21 -56 -23 -79 -55 -127 -172 -25 -63 -46 -119 -46 -124 0 -5 17 -38 39
    -74 55 -94 57 -106 27 -141 -15 -18 -27 -49 -31 -78 -4 -26 -9 -56 -12 -67 -6
    -20 -6 -20 -14 0 -15 39 -9 103 12 140 12 19 19 41 16 48 -3 8 -32 23 -64 35
    -33 12 -70 33 -83 47 -17 18 -34 25 -60 25 -31 0 -38 -5 -52 -33 -8 -17 -19
    -47 -22 -65 -8 -32 -7 -32 32 -32 43 0 52 -10 52 -59 0 -18 -12 -38 -33 -59
    -43 -42 -78 -42 -107 -2 -14 20 -30 30 -48 30 -37 1 -62 22 -62 53 0 20 6 27
    29 32 16 3 36 14 45 24 15 16 14 22 -14 77 -24 48 -36 62 -65 73 -33 11 -36
    15 -30 41 5 25 2 31 -23 44 -24 13 -49 13 -158 5 -71 -5 -169 -19 -217 -29
    -63 -14 -94 -17 -107 -10 -26 14 -81 12 -179 -5 -89 -15 -128 -36 -201 -102
    -41 -36 -49 -53 -27 -53 6 0 18 -4 26 -9 11 -7 10 -14 -4 -39 -16 -26 -21 -28
    -36 -19 -15 9 -22 8 -33 -3 -17 -19 -56 -136 -56 -171 0 -42 20 -51 112 -49
    147 4 142 8 108 -87 -11 -32 -20 -70 -20 -86 0 -17 -5 -47 -12 -69 -9 -29 -9
    -41 0 -50 8 -8 12 -49 12 -114 l0 -101 -54 -84 c-47 -73 -58 -100 -82 -205
    l-29 -122 -53 -5 c-30 -3 -65 -2 -79 4 -43 16 -113 11 -149 -12 -38 -23 -44
    -51 -16 -82 9 -11 12 -17 6 -14 -7 4 -22 -1 -35 -12 -21 -17 -22 -21 -11 -59
    9 -29 23 -47 48 -62 31 -20 46 -22 142 -19 l107 4 42 -45 42 -46 -30 -49 c-27
    -45 -29 -56 -25 -108 6 -56 4 -60 -25 -85 -17 -15 -72 -50 -122 -77 -72 -39
    -96 -58 -109 -85 -24 -50 -22 -66 17 -117 19 -25 35 -49 35 -52 0 -15 -155
    -62 -282 -87 -131 -25 -138 -28 -138 -50 0 -13 -3 -24 -6 -24 -3 0 -54 21
    -112 47 -80 36 -107 44 -110 34 -9 -28 -40 -48 -83 -55 -24 -4 -60 -11 -80
    -16 l-36 -10 14 -62 c10 -44 25 -75 54 -109 29 -35 39 -56 39 -82 0 -29 7 -41
    43 -70 97 -80 93 -73 101 -185 l8 -101 -82 -121 c-45 -67 -80 -123 -78 -125 2
    -2 23 0 48 5 25 5 46 7 48 5 2 -2 -8 -26 -22 -53 -22 -43 -36 -56 -101 -92
    -63 -35 -82 -41 -111 -36 l-34 7 0 -54 0 -55 63 -17 c57 -15 66 -21 90 -61 15
    -25 27 -47 27 -50 0 -4 -13 -23 -30 -44 -29 -37 -36 -71 -24 -117 5 -18 14
    -23 56 -25 l50 -3 23 -54 c20 -49 56 -83 110 -105 6 -2 -10 -21 -34 -43 l-43
    -38 18 -38 c16 -33 22 -37 56 -37 27 0 46 -8 69 -28 l31 -28 -12 -57 -13 -58
    33 -34 34 -35 -33 0 c-24 0 -50 -13 -95 -46 -33 -26 -67 -49 -73 -51 -7 -3
    -22 -37 -34 -77 -31 -102 -22 -200 25 -293 30 -60 43 -75 112 -122 51 -36 96
    -59 132 -67 30 -7 58 -16 62 -21 4 -4 15 -32 23 -62 l16 -55 -85 -79 -84 -79
    0 -79 0 -79 31 0 c22 0 52 -15 100 -51 66 -49 69 -53 69 -93 0 -35 6 -48 36
    -77 20 -18 49 -62 65 -95 l28 -62 76 -25 75 -26 0 50 c0 27 5 49 10 49 6 0 45
    -10 86 -22 82 -24 94 -37 94 -101 l1 -32 25 29 26 29 63 -11 c87 -17 100 -15
    145 13 l40 25 65 -17 c59 -16 66 -20 79 -54 15 -36 20 -39 108 -66 90 -28 97
    -29 214 -20 l121 9 51 -36 c46 -34 58 -37 136 -43 47 -3 86 -7 86 -9 0 -1 -33
    -59 -72 -127 -71 -121 -77 -129 -193 -227 -66 -57 -126 -109 -133 -118 -7 -9
    -12 -45 -12 -88 0 -64 -6 -85 -55 -204 -30 -73 -71 -168 -91 -211 -23 -52 -42
    -116 -54 -187 l-18 -107 24 -41 23 -41 -44 -112 c-40 -98 -45 -120 -45 -188 0
    -55 6 -94 21 -133 11 -30 22 -56 23 -58 2 -1 19 5 39 13 l37 15 0 -45 c0 -25
    4 -45 9 -45 10 0 19 3 106 38 l51 20 57 -24 c51 -22 67 -24 132 -18 66 6 77
    10 106 40 30 31 38 34 90 34 51 0 60 -3 82 -30 l25 -30 119 16 c182 24 167 16
    159 77 -4 28 -9 53 -11 55 -2 2 -20 -5 -42 -16 -70 -36 -78 -36 -95 0 -21 46
    -5 87 52 132 37 30 53 36 93 36 41 0 51 -4 76 -32 27 -31 28 -35 16 -65 -12
    -29 -12 -32 8 -37 12 -3 41 -6 65 -6 l43 0 -7 35 c-4 22 -2 35 4 35 6 0 17 3
    25 6 11 4 20 -9 32 -48 10 -29 18 -54 19 -55 1 -1 30 6 64 16 l61 19 83 -24
    c46 -13 108 -24 139 -24 54 0 59 -3 189 -95 l133 -95 68 0 c68 0 68 0 110 45
    23 25 45 45 49 45 4 0 13 -11 20 -25 11 -21 21 -25 59 -25 75 0 113 -16 166
    -70 l49 -50 -12 -47 c-15 -56 -12 -60 71 -69 31 -4 57 -11 57 -16 0 -5 -7 -25
    -15 -45 -8 -20 -13 -38 -11 -40 7 -7 168 24 175 34 3 5 28 41 54 80 45 65 48
    73 39 105 -10 35 -5 138 7 138 3 -1 26 -5 51 -10 28 -5 59 -5 83 1 35 9 43 7
    88 -22 l50 -32 81 22 c44 12 81 21 82 19 1 -2 12 -41 25 -88 12 -47 26 -89 31
    -94 13 -13 147 8 208 32 56 23 54 24 66 -30 l6 -28 76 53 c48 33 80 62 86 80
    10 26 13 27 76 27 37 0 72 4 77 8 6 4 24 28 40 54 30 47 31 48 85 49 30 0 152
    6 270 12 240 13 222 6 206 88 -7 34 -5 38 27 58 19 11 37 21 41 21 3 0 6 -16
    6 -35 0 -19 1 -35 3 -35 1 0 34 9 72 20 38 11 77 20 87 20 9 0 28 -20 42 -45
    25 -42 29 -45 71 -45 24 0 65 9 92 21 39 17 59 19 109 14 l62 -6 7 -56 c5 -38
    17 -72 38 -104 35 -52 52 -59 143 -59 l54 0 0 48 c0 41 5 53 30 77 33 32 35
    42 19 98 -12 43 -40 60 -120 71 l-46 7 40 77 40 78 -23 57 c-18 44 -34 63 -70
    88 -40 28 -49 39 -56 78 -8 40 -13 47 -39 51 -30 5 -30 6 -27 60 3 46 10 62
    44 102 37 44 44 48 84 48 38 0 51 6 88 40 40 37 52 42 145 59 109 20 91 9 247
    146 33 29 35 34 41 115 5 74 10 90 36 122 l29 36 71 -39 c119 -66 113 -64 152
    -43 20 10 49 36 66 57 l31 38 -23 61 -22 61 24 69 24 69 -32 29 c-18 17 -38
    39 -45 49 -8 11 -29 23 -48 28 -27 7 -36 17 -48 50 -13 33 -22 43 -52 52 -51
    15 -71 14 -85 -6 -11 -15 -16 -14 -57 12 -40 25 -47 35 -57 81 -10 45 -20 60
    -61 94 -42 35 -51 39 -74 31 -22 -9 -28 -6 -50 22 -14 18 -31 38 -38 44 -81
    78 -142 149 -140 163 1 8 -18 30 -43 47 -45 33 -92 42 -112 22 -17 -17 -112
    28 -119 57 -4 14 -23 33 -47 46 -43 24 -54 46 -56 110 -1 32 -5 37 -30 43 -26
    6 -31 12 -36 49 -8 49 -12 57 -40 72 -14 8 -20 22 -20 45 -1 31 -5 35 -42 48
    -38 14 -73 52 -73 80 0 6 14 17 30 26 24 13 30 22 30 49 0 27 5 35 23 40 23 5
    74 92 62 105 -4 3 -21 11 -38 17 -26 9 -31 16 -29 38 3 26 -4 30 -40 27 -12
    -2 -18 5 -18 17 0 13 -18 29 -59 51 -32 17 -66 31 -75 31 -9 0 -16 8 -16 20 0
    12 -14 29 -35 42 -29 19 -34 27 -30 47 10 39 -12 77 -56 96 -40 18 -52 48 -25
    58 9 4 12 14 9 25 -7 29 35 41 54 16 7 -10 13 -24 13 -31 0 -7 8 -13 18 -13
    22 0 72 -62 72 -89 0 -14 7 -21 19 -21 15 0 22 12 31 51 10 41 23 61 69 106
    131 128 140 133 239 133 51 0 73 5 92 20 14 11 32 20 41 20 8 0 19 4 25 10 6
    6 25 -1 54 -20 35 -23 52 -29 75 -24 39 8 85 52 85 80 0 21 6 23 81 30 75 6
    81 9 90 33 6 14 20 36 31 48 16 17 25 20 45 12 28 -10 43 -5 43 17 0 8 5 14
    11 14 7 0 18 8 25 17 12 17 14 17 36 -3 34 -31 69 -36 96 -14 12 10 22 27 22
    37 0 10 4 23 9 29 5 5 11 18 13 28 3 22 88 48 125 39 12 -3 32 2 43 10 16 11
    37 14 75 10 33 -4 55 -1 59 6 4 6 16 11 26 11 14 0 19 6 17 22 -3 32 52 61
    123 64 32 1 68 9 81 17 13 9 43 20 66 26 23 7 50 22 61 35 15 19 32 25 86 31
    56 6 69 11 76 29 10 28 6 33 -34 40 -22 4 -37 14 -41 26 -4 11 -17 23 -31 26
    -29 7 -28 5 -7 49 9 19 24 35 32 35 29 0 91 -21 91 -30 0 -13 47 -13 72 1 16
    9 24 7 42 -10 12 -12 29 -21 37 -21 8 0 20 -9 27 -20 10 -16 9 -27 -3 -57 -17
    -41 -13 -53 15 -35 37 23 49 16 39 -25 -5 -21 -9 -41 -9 -44 0 -13 108 -59
    137 -59 36 0 63 27 63 63 0 19 11 33 40 54 28 20 40 35 40 53 0 14 10 41 21
    60 12 19 41 102 65 185 48 163 49 187 8 242 -14 18 -24 45 -24 60 2 56 -8 115
    -21 128 -8 8 -61 31 -119 53 -119 45 -125 53 -90 123 27 53 21 100 -17 124
    -44 29 -73 66 -73 93 0 14 -12 38 -26 53 -20 21 -25 34 -20 53 3 14 13 26 21
    26 8 0 15 4 15 8 0 4 16 25 35 45 36 38 39 48 36 107 -1 19 5 49 13 65 25 47
    20 72 -15 91 -27 13 -30 19 -24 42 3 15 8 58 10 96 l4 68 -60 27 c-46 20 -59
    31 -59 47 0 12 -10 47 -21 78 -22 56 -20 106 3 106 7 0 25 12 40 27 29 27 36
    61 18 79 -7 7 -7 21 1 43 17 49 -1 72 -93 117 -60 29 -92 52 -126 92 -25 29
    -62 60 -81 68 -20 8 -52 30 -71 48 -21 21 -50 36 -73 40 -37 7 -38 9 -31 34 5
    15 9 55 9 90 l0 63 75 45 c41 25 87 57 103 72 32 31 51 92 43 140 -4 26 2 46
    25 87 29 51 30 55 15 84 -9 16 -16 42 -16 57 0 45 -51 218 -71 240 -14 15 -19
    35 -19 75 0 33 -6 63 -15 75 -8 10 -15 33 -15 50 0 34 -16 54 -42 54 -25 0
    -23 13 4 25 30 13 34 41 9 55 -16 8 -28 7 -53 -6 -61 -31 -165 -8 -273 61 -22
    14 -52 31 -67 37 -41 18 -36 50 15 92 l42 35 -69 66 c-51 48 -63 65 -48 65 11
    0 25 7 32 15 21 25 43 19 36 -10 -5 -20 -3 -25 12 -25 25 0 42 19 42 47 0 15
    6 23 18 23 24 0 62 -28 62 -45 0 -7 10 -15 23 -17 18 -2 22 -9 22 -38 0 -31
    -3 -35 -23 -33 -13 2 -32 -2 -42 -7 -18 -10 -19 -8 -14 20 6 28 5 30 -24 30
    -40 0 -46 -11 -26 -53 24 -51 13 -81 -33 -93 -42 -10 -55 -37 -26 -52 20 -11
    132 3 148 19 5 5 52 9 105 9 l97 0 0 46 c0 43 -3 48 -56 92 -30 26 -75 66
    -100 88 -32 29 -59 44 -87 48 -49 9 -105 52 -128 101 -13 26 -23 35 -42 35
    -17 0 -28 -7 -31 -20 -3 -11 -13 -20 -22 -20 -24 0 -33 11 -21 25 23 28 -20
    22 -66 -9 -35 -23 -64 -35 -102 -38 -62 -6 -67 -10 -52 -38 10 -19 9 -21 -11
    -18 -15 2 -26 14 -33 36 -6 18 -17 37 -24 41 -8 4 -10 15 -6 26 7 16 3 17 -34
    11 -33 -5 -42 -3 -42 8 0 8 -6 19 -13 23 -8 4 -22 20 -32 36 -11 18 -26 27
    -43 27 -14 0 -33 7 -42 15 -9 8 -34 18 -56 23 -33 7 -39 12 -39 34 0 14 -8 35
    -18 46 -12 13 -15 22 -8 27 18 11 12 35 -8 35 -50 0 -60 51 -20 95 10 12 22
    35 25 52 3 18 15 40 25 49 11 9 19 28 19 41 0 12 5 23 10 23 6 0 10 9 10 21 0
    15 -5 20 -22 17 -17 -2 -24 -12 -29 -38 -3 -19 -11 -46 -19 -60 -8 -14 -16
    -41 -20 -60 -7 -45 -19 -47 -220 -49 -129 -1 -166 3 -218 19 -106 33 -116 30
    -166 -48 -24 -37 -54 -88 -66 -112 -18 -39 -33 -51 -97 -85 -44 -23 -92 -58
    -113 -82 -35 -40 -41 -43 -88 -43 -27 0 -92 -9 -145 -20 -73 -15 -111 -17
    -166 -11 -71 7 -72 7 -111 -26 -31 -26 -43 -47 -56 -95 l-17 -60 -48 6 c-56 8
    -111 -11 -128 -42 -15 -28 -14 -32 9 -32 10 0 30 -5 44 -11 21 -10 23 -15 14
    -35 -13 -28 -17 -29 -43 -11 -11 7 -35 19 -53 27 -29 11 -34 11 -45 -4 -19
    -26 -67 -5 -67 29 0 18 -7 26 -24 31 -14 3 -27 12 -30 20 -7 19 -66 18 -155
    -2 -58 -13 -76 -22 -93 -45 -27 -36 -34 -36 -50 -1 -9 19 -27 31 -62 42 -65
    21 -83 52 -53 95 11 16 21 31 22 32 1 1 18 -2 39 -8 36 -10 39 -8 79 28 23 21
    70 56 105 78 l64 40 -6 65 c-3 36 -8 77 -12 91 -4 19 1 34 22 59 l29 33 -61
    -6 c-34 -4 -75 -2 -93 3 -26 7 -34 6 -44 -11 -6 -10 -20 -19 -31 -19 -10 0
    -41 -16 -67 -35 -69 -49 -112 -48 -163 4 -31 32 -49 41 -99 51 -34 6 -77 20
    -97 30 -67 34 -116 42 -176 29 -51 -12 -56 -16 -85 -66 -31 -53 -52 -66 -63
    -38 -5 15 12 55 24 55 3 0 11 4 19 9 10 7 11 13 1 30 -9 17 -9 26 0 36 9 11 5
    18 -22 35 -38 24 -80 22 -212 -11 -64 -16 -81 -18 -87 -7 -17 26 -8 37 39 52
    26 9 66 34 90 57 39 37 42 44 42 92 0 46 -3 55 -32 79 l-33 27 27 1 c34 0 44
    16 20 29 -10 6 -26 30 -36 54 -10 28 -26 48 -42 56 -23 10 -26 9 -35 -19 -9
    -26 -15 -29 -37 -24 -15 3 -34 15 -43 25 -10 10 -59 34 -110 52 -72 25 -95 38
    -99 54 -5 21 -9 20 -85 -27 -81 -49 -94 -50 -95 -6 0 17 -4 16 -32 -11 -18
    -17 -48 -34 -66 -38 -30 -6 -37 -3 -65 27 -28 30 -47 38 -142 63 -138 35 -205
    45 -227 33 -12 -6 -26 -4 -42 7 -22 14 -28 15 -52 2 -24 -12 -35 -12 -82 0
    -50 13 -56 13 -64 -1 -7 -13 -29 -16 -117 -16 -94 0 -112 3 -135 21 -21 17
    -26 29 -26 65 0 39 3 46 28 55 45 18 54 24 50 32 -2 5 5 13 16 19 15 8 16 12
    5 19 -35 22 -64 10 -92 -38z"
        />
        <path
          d="M3145 9843 c-17 -2 -37 -13 -44 -25 -21 -33 14 -60 85 -66 51 -4 59
    -2 74 18 12 16 15 31 11 49 -6 23 -13 26 -52 27 -24 1 -58 -1 -74 -3z"
        />
        <path
          d="M2992 9757 c-28 -30 -28 -48 1 -75 27 -26 59 -28 78 -6 11 13 10 17
    -4 25 -9 6 -25 26 -35 45 l-18 35 -22 -24z"
        />
        <path
          d="M7960 9741 c-76 -7 -130 -38 -130 -75 0 -13 -9 -31 -20 -41 -29 -27
    -16 -31 19 -6 20 14 28 27 24 37 -6 16 12 34 35 34 19 0 14 -25 -8 -45 -11
    -10 -20 -29 -20 -42 0 -20 -5 -23 -41 -23 -38 0 -41 -2 -35 -22 3 -13 6 -26 6
    -30 0 -5 14 -8 30 -8 17 0 30 -4 30 -10 0 -5 -15 -10 -34 -10 -25 0 -40 -7
    -55 -26 -25 -32 -26 -44 -6 -44 8 0 15 -4 15 -10 0 -5 15 -10 34 -10 22 0 36
    -6 40 -16 13 -34 6 -41 -44 -48 -46 -6 -50 -9 -50 -34 0 -19 10 -35 35 -55 19
    -15 35 -34 35 -42 0 -8 8 -15 18 -15 9 0 50 -14 91 -30 83 -34 94 -36 110 -16
    9 10 9 20 0 39 -14 31 3 67 31 67 11 0 20 7 20 15 0 9 9 15 24 15 14 0 37 5
    52 10 20 8 32 7 50 -5 19 -12 27 -13 44 -3 29 19 50 -4 27 -30 -16 -17 -15
    -19 18 -34 35 -15 35 -15 35 9 0 13 12 35 26 48 15 14 22 25 17 25 -5 0 -26
    16 -46 35 -28 27 -44 35 -72 35 -32 0 -38 4 -51 35 -24 58 -19 74 31 96 56 25
    71 62 36 90 -19 16 -35 17 -119 12 l-98 -6 -32 33 c-37 37 -37 49 1 68 30 15
    37 43 10 40 -10 0 -47 -4 -83 -7z"
        />
        <path
          d="M3325 9562 c-27 -8 -52 -16 -54 -18 -9 -8 31 -54 48 -54 31 0 63 33
    59 61 l-3 25 -50 -14z"
        />
        <path
          d="M3140 9558 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
    -5 3 -10 0 -10 -7z"
        />
        <path
          d="M5687 9532 c-19 -20 -27 -39 -27 -65 0 -40 22 -59 43 -38 17 17 37
    13 37 -9 0 -10 8 -23 18 -28 19 -10 202 -13 201 -3 0 3 -17 33 -37 65 -35 56
    -41 60 -97 76 -114 32 -109 32 -138 2z"
        />
        <path
          d="M3110 9465 c0 -19 27 -55 42 -55 20 0 23 12 8 41 -9 16 -50 28 -50
    14z"
        />
      </g>
    </svg>
  );
};
