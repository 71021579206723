import { AustriaSvg } from "./svgs/austria";
import { GermanySvg } from "./svgs/germany";
import { NetherlandsSvg } from "./svgs/netherlands";
import { SingaporeSvg } from "./svgs/singapore";
import { SpainSvg } from "./svgs/spain";
import { SwitzerlandSvg } from "./svgs/switzerland";
import { UAESvg } from "./svgs/uae";
import { UKSvg } from "./svgs/uk";

export const getCountrySvg = (
  countryName: string,
  fill?: string,
  width?: string,
  height?: string
) => {
  switch (countryName) {
    case "austria":
      return <AustriaSvg fill={fill} width={width} height={height} />;
    case "netherlands":
      return <NetherlandsSvg fill={fill} width={width} height={height} />;
    case "germany":
      return <GermanySvg fill={fill} width={width} height={height} />;
    case "uae":
      return <UAESvg fill={fill} width={width} height={height} />;
    case "uk":
      return <UKSvg fill={fill} width={width} height={height} />;
    case "singapore":
      return <SingaporeSvg fill={fill} width={width} height={height} />;
    case "spain":
      return <SpainSvg fill={fill} width={width} height={height} />;
    case "switzerland":
      return <SwitzerlandSvg fill={fill} width={width} height={height} />;
    default:
      return <SpainSvg fill={fill} width={width} height={height} />;
  }
};
