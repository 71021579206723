import { TextCardsType } from "./TextCards";

export const experienceTextCardsData: TextCardsType[] = [
    {
      headline: "ai.experience.cards.a.headline",
      text: "ai.experience.cards.a.text",
    },
    {
      headline: "ai.experience.cards.b.headline",
      text: "ai.experience.cards.b.text",
    },
    {
      headline: "ai.experience.cards.c.headline",
      text: "ai.experience.cards.c.text",
    },
    {
      headline: "ai.experience.cards.d.headline",
      text: "ai.experience.cards.d.text",
    },
    {
      headline: "ai.experience.cards.e.headline",
      text: "ai.experience.cards.e.text",
    },
    {
      headline: "ai.experience.cards.f.headline",
      text: "ai.experience.cards.f.text",
    },
  ];