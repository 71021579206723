export const UKSvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke={stroke}
      >
        <path
          d="M5190 10215 c0 -24 25 -45 54 -45 9 0 16 9 16 20 0 13 -7 20 -20 20
    -11 0 -23 7 -26 15 -9 24 -24 18 -24 -10z"
        />
        <path
          d="M5062 10162 c-14 -14 -21 -41 -26 -112 -4 -51 -5 -94 -4 -96 2 -2 14
    -1 25 2 18 5 20 9 11 28 -7 18 -5 30 10 55 l20 33 17 -26 c15 -22 15 -31 5
    -55 -17 -42 -10 -75 18 -79 12 -2 47 8 78 22 l55 25 10 -27 c8 -22 66 -72 84
    -72 10 0 5 39 -6 46 -6 3 -9 17 -6 30 3 20 1 22 -14 14 -30 -16 -39 -11 -39
    20 0 29 -1 30 -42 24 -54 -7 -89 14 -72 43 6 10 21 22 33 28 32 15 22 35 -38
    77 -60 42 -92 47 -119 20z"
        />
        <path
          d="M4989 9879 c-9 -18 -8 -24 4 -30 8 -5 29 -32 46 -59 17 -28 35 -50
    39 -50 15 0 42 35 42 54 0 22 -17 55 -43 84 -27 29 -72 29 -88 1z"
        />
        <path
          d="M5260 9752 c0 -16 7 -37 15 -48 14 -18 14 -17 15 13 0 17 3 38 6 47
    4 12 0 16 -15 16 -16 0 -21 -6 -21 -28z"
        />
        <path
          d="M5000 9561 c0 -6 5 -13 10 -16 6 -3 10 -19 10 -35 l0 -29 -42 19
    c-26 12 -65 20 -99 20 -46 0 -62 -5 -97 -31 -36 -26 -51 -31 -98 -30 -32 0
    -66 7 -79 15 -20 13 -24 13 -29 1 -3 -8 -14 -15 -24 -15 -10 0 -40 -14 -66
    -31 -35 -24 -50 -29 -59 -20 -26 26 -63 2 -103 -65 -21 -34 -28 -4 -11 42 16
    43 16 45 -3 64 -11 11 -33 20 -50 20 -25 0 -30 -4 -30 -23 0 -14 -20 -43 -55
    -77 -40 -39 -55 -49 -55 -36 0 10 11 31 25 48 14 16 25 36 25 43 0 14 -38 55
    -49 55 -3 0 -13 -17 -23 -38 l-16 -37 -1 31 c-1 30 -39 84 -60 84 -5 0 -16 3
    -25 6 -12 5 -16 0 -16 -19 0 -14 -17 -45 -40 -73 -46 -56 -48 -64 -19 -89 l20
    -19 -23 -18 c-22 -17 -22 -19 -5 -32 24 -19 21 -29 -7 -22 -36 9 -47 -12 -37
    -72 5 -33 17 -62 32 -78 18 -20 20 -24 6 -18 -10 4 -46 -1 -79 -11 -46 -14
    -64 -16 -78 -7 -26 16 -32 3 -11 -27 69 -97 82 -191 27 -191 -12 0 -32 9 -45
    21 -12 12 -25 18 -28 15 -14 -14 21 -59 87 -113 53 -43 69 -62 64 -76 -4 -12
    7 -32 35 -62 23 -25 41 -52 41 -62 0 -11 -18 2 -53 39 -43 45 -61 56 -93 60
    -21 3 -40 4 -41 3 -2 -1 17 -17 42 -35 25 -19 45 -40 45 -47 0 -20 -10 -16
    -66 21 l-51 35 -12 -25 c-9 -21 -17 -25 -44 -22 -25 2 -35 10 -46 36 -8 17
    -18 32 -23 32 -18 0 -20 -33 -4 -66 18 -38 22 -114 6 -114 -19 0 -50 40 -50
    64 0 43 -9 53 -40 48 -29 -4 -30 -6 -30 -61 1 -54 2 -57 40 -83 22 -15 40 -35
    40 -43 0 -18 -12 -19 -29 -2 -10 10 -16 7 -32 -12 -27 -33 -24 -46 18 -97 30
    -35 44 -44 69 -44 27 0 64 -25 64 -43 0 -4 -15 -7 -34 -7 -22 0 -36 -6 -40
    -16 -5 -13 -11 -12 -37 10 -17 15 -44 33 -59 41 l-28 15 -7 -63 c-5 -47 -3
    -69 9 -92 9 -16 16 -37 16 -46 0 -8 6 -24 14 -34 13 -19 15 -18 55 8 l42 28
    19 -23 18 -23 31 29 c29 27 31 28 31 9 0 -25 -18 -41 -83 -73 -65 -32 -72 -50
    -20 -50 37 0 121 -42 137 -69 10 -16 2 -14 -44 10 -34 18 -45 20 -67 10 -20
    -9 -23 -14 -13 -26 10 -11 8 -21 -9 -48 l-21 -34 60 -29 c33 -16 60 -33 60
    -37 0 -14 -30 -19 -58 -9 -63 24 -81 24 -108 -3 -28 -28 -28 -47 3 -55 11 -3
    34 -22 52 -42 31 -35 31 -37 10 -43 -14 -5 -28 0 -43 14 -12 12 -33 21 -47 21
    -21 0 -27 -9 -46 -68 -11 -37 -20 -68 -19 -70 2 -1 17 3 35 9 72 25 81 -31 13
    -81 -23 -17 -43 -38 -43 -48 -1 -9 -2 -21 -4 -27 -1 -5 -3 -14 -3 -19 -1 -5
    -11 -1 -22 9 -16 15 -30 17 -80 12 -34 -4 -70 -11 -80 -17 -24 -13 -26 -55 -4
    -64 32 -12 208 -6 234 9 34 19 68 18 110 -5 l35 -19 -60 -1 c-34 0 -65 -4 -71
    -10 -5 -5 -34 -14 -64 -20 -29 -6 -56 -12 -59 -15 -14 -14 59 -91 117 -123 77
    -42 94 -39 147 30 22 29 91 106 153 172 61 65 112 123 112 127 0 5 -19 13 -42
    19 -66 17 -77 23 -46 27 28 4 93 -10 118 -26 12 -8 7 -20 -28 -72 -27 -39 -41
    -68 -37 -79 4 -13 -3 -24 -25 -36 -16 -10 -30 -21 -30 -25 0 -3 -13 -26 -30
    -50 -37 -55 -40 -90 -7 -81 20 5 21 4 8 -11 -7 -9 -24 -17 -36 -17 -30 0 -38
    -23 -14 -38 10 -7 19 -16 19 -20 0 -5 -25 -26 -55 -47 -52 -35 -68 -55 -45
    -55 21 0 9 -34 -25 -71 -46 -51 -46 -69 1 -69 36 0 36 0 29 -32 -5 -18 -7 -58
    -6 -89 1 -41 -3 -61 -17 -80 -55 -70 -85 -163 -63 -198 7 -11 11 -10 19 5 20
    36 25 3 8 -51 -17 -54 -17 -56 3 -89 27 -44 26 -72 -3 -99 -24 -23 -31 -55
    -44 -203 -6 -65 -12 -89 -34 -120 -51 -73 -31 -152 32 -134 88 25 123 76 80
    115 -19 17 -19 18 0 44 19 28 60 175 60 219 0 63 36 130 84 157 16 9 14 18
    -28 104 -35 72 -46 106 -46 143 0 47 0 47 29 42 26 -6 31 -1 66 56 21 35 54
    72 76 87 21 14 39 29 39 33 0 3 16 25 36 48 25 30 43 42 62 42 l26 0 -24 -17
    c-14 -9 -31 -25 -39 -36 -7 -10 -42 -51 -76 -90 -72 -80 -106 -142 -112 -201
    -5 -48 19 -102 57 -125 19 -11 22 -11 13 -1 -17 19 -16 45 2 80 17 33 36 38
    55 15 22 -27 29 -16 14 21 -8 20 -13 39 -10 42 12 11 42 -29 60 -78 10 -27 21
    -50 26 -50 12 0 42 90 37 111 -3 10 -1 19 4 19 5 0 9 46 9 103 1 101 1 102 19
    80 l19 -23 18 27 c24 37 30 13 10 -38 -9 -23 -14 -49 -11 -60 10 -30 114 -118
    153 -129 102 -28 76 -50 -32 -27 -116 24 -134 23 -142 -8 -3 -14 -1 -61 5
    -105 10 -63 9 -84 -1 -102 -19 -35 -12 -47 59 -118 36 -36 71 -81 78 -100 7
    -19 16 -43 20 -53 6 -14 -3 -28 -37 -60 -50 -47 -98 -127 -98 -164 0 -29 -21
    -72 -64 -133 -18 -25 -41 -75 -50 -112 -17 -62 -17 -69 -2 -114 23 -67 21 -84
    -8 -84 -31 0 -56 34 -56 77 0 45 -14 41 -34 -8 -16 -38 -15 -43 4 -87 12 -26
    41 -67 65 -92 47 -48 65 -84 65 -129 0 -26 16 -51 32 -51 15 0 8 37 -17 94
    -32 73 -32 89 6 120 35 29 51 32 68 12 7 -8 27 -17 45 -20 25 -5 46 -21 81
    -64 55 -66 90 -92 126 -92 25 0 27 4 32 57 5 47 2 64 -15 95 -31 55 -13 68 41
    32 29 -19 45 -24 52 -17 8 8 21 -1 46 -28 28 -31 38 -37 49 -28 11 9 19 8 33
    -5 24 -21 46 -13 134 52 52 38 80 51 119 56 l50 7 -7 55 -7 56 29 -6 c46 -9
    246 -12 278 -3 26 6 32 4 42 -18 16 -35 -13 -51 -63 -35 -42 14 -93 5 -75 -13
    30 -30 -2 -67 -40 -46 -17 9 -22 5 -40 -36 -11 -26 -18 -53 -15 -61 3 -7 -17
    -46 -44 -86 -39 -58 -50 -81 -50 -113 0 -23 -9 -58 -20 -79 l-19 -39 32 -37
    c47 -56 97 -143 118 -205 27 -83 36 -95 69 -95 35 0 34 2 16 -41 -13 -30 -12
    -37 4 -69 34 -67 46 -68 74 -7 14 30 35 62 46 72 19 17 21 17 39 1 19 -17 21
    -17 49 15 l30 34 23 -48 23 -47 -32 -45 c-33 -47 -38 -64 -23 -80 17 -18 -7
    -51 -53 -76 l-46 -24 0 -81 c0 -99 8 -109 80 -99 27 4 50 4 50 2 0 -3 -25 -29
    -55 -58 -70 -66 -99 -112 -92 -147 9 -50 77 -197 99 -215 18 -15 32 -18 77
    -12 l54 7 -22 -31 c-63 -87 -106 -71 -175 66 -15 29 -29 53 -32 53 -12 0 -64
    -35 -64 -42 0 -5 16 -40 35 -78 19 -38 35 -79 35 -90 0 -18 -3 -19 -22 -9 -13
    7 -51 40 -86 73 l-63 60 -108 -37 -108 -38 -59 20 c-72 26 -71 26 -78 -2 -4
    -17 -19 -27 -59 -40 -75 -24 -80 -23 -72 13 6 29 5 30 -30 30 -25 0 -42 7 -55
    22 -77 89 -88 98 -122 97 -64 -2 -80 -12 -93 -57 -16 -53 -32 -73 -52 -65 -13
    5 -14 3 -4 -16 7 -12 23 -26 36 -31 12 -5 40 -30 61 -55 22 -25 54 -55 72 -66
    28 -18 32 -26 32 -62 0 -38 -5 -46 -53 -88 -29 -26 -70 -56 -92 -67 -22 -11
    -52 -33 -67 -49 -27 -28 -63 -101 -55 -110 3 -2 23 5 46 16 52 27 78 27 87 -1
    7 -21 8 -20 17 12 15 60 56 92 134 105 29 5 50 2 73 -10 37 -19 59 -60 42 -77
    -18 -18 -15 -34 14 -78 16 -24 31 -37 40 -33 23 8 16 -10 -16 -45 -16 -18 -30
    -44 -30 -58 0 -39 28 -84 52 -84 19 0 20 -2 6 -17 -8 -10 -21 -38 -29 -63 -7
    -25 -34 -85 -59 -135 -40 -79 -51 -93 -91 -115 -26 -14 -67 -44 -91 -66 -44
    -41 -57 -46 -146 -59 -34 -6 -50 -15 -80 -52 -20 -24 -52 -53 -70 -65 -30 -19
    -34 -19 -51 -4 -25 23 -50 20 -61 -8 -10 -27 -40 -50 -80 -61 -27 -8 -60 -33
    -60 -47 0 -4 11 -8 25 -8 38 0 75 -21 91 -51 17 -34 12 -49 -26 -71 -30 -17
    -39 -41 -19 -54 6 -3 16 -1 22 5 12 12 58 14 106 5 25 -5 29 -9 20 -20 -6 -8
    -24 -14 -39 -14 -14 0 -29 -3 -33 -6 -11 -11 15 -52 40 -63 35 -16 48 -14 89
    14 20 14 47 25 60 25 13 0 31 11 41 25 52 74 47 71 97 57 35 -9 51 -9 71 0 24
    11 29 9 51 -20 18 -23 34 -32 55 -32 24 0 29 -4 29 -23 0 -13 -9 -32 -20 -42
    -25 -22 -25 -37 -2 -58 23 -21 51 -21 76 -2 11 8 33 15 49 15 16 0 47 10 69
    22 36 21 41 21 66 7 15 -8 40 -38 57 -67 16 -28 50 -67 73 -84 107 -80 102
    -78 166 -78 83 1 111 16 154 79 l37 55 91 22 c100 24 139 41 168 73 18 20 18
    25 4 113 -15 91 -15 94 8 161 22 64 22 71 8 99 -16 30 -14 45 5 34 20 -13 23
    -72 5 -131 -12 -43 -15 -80 -11 -138 3 -43 8 -81 11 -84 6 -6 45 22 110 79 36
    31 38 36 25 55 -17 28 -6 39 25 25 24 -11 24 -12 10 -41 -8 -17 -47 -65 -86
    -108 -39 -43 -80 -98 -92 -122 -13 -27 -39 -58 -66 -78 -25 -18 -68 -53 -95
    -77 -47 -41 -51 -48 -62 -111 -14 -80 -33 -106 -63 -87 -17 10 -30 8 -81 -14
    l-61 -25 -52 25 c-46 23 -64 26 -181 27 -102 2 -150 -2 -223 -19 -104 -23
    -121 -36 -122 -94 0 -21 -7 -56 -16 -78 -19 -47 -51 -60 -98 -41 -65 26 -64
    27 -81 -33 -12 -40 -14 -72 -10 -112 l7 -55 -71 -71 c-39 -39 -71 -77 -71 -83
    0 -19 -42 -52 -91 -73 -52 -23 -79 -54 -79 -93 0 -17 -13 -37 -40 -62 -22 -19
    -40 -44 -40 -54 0 -26 -27 -54 -118 -120 -60 -45 -78 -54 -92 -45 -20 12 -67
    -8 -111 -49 -22 -20 -29 -36 -29 -63 0 -20 4 -36 9 -36 27 0 71 25 91 51 19
    26 27 30 52 25 48 -9 106 -57 130 -106 11 -25 22 -46 23 -48 2 -2 23 11 49 28
    47 32 59 62 31 78 -14 7 -13 13 4 53 11 24 21 52 21 62 0 29 24 14 30 -19 l5
    -29 13 31 c14 33 80 68 108 57 9 -3 14 1 14 13 0 29 27 98 39 102 6 2 18 -4
    27 -13 9 -8 24 -13 33 -10 9 2 55 13 103 24 85 18 87 18 130 -1 43 -19 43 -19
    60 1 19 24 38 27 38 8 0 -23 33 -45 80 -52 72 -11 80 -15 98 -53 19 -40 36
    -47 88 -37 32 6 34 8 34 45 0 41 10 56 64 93 32 22 33 50 4 70 l-23 15 27 12
    c22 10 25 16 18 35 -6 16 -2 36 15 73 19 41 30 52 62 62 21 7 56 25 78 40 24
    17 65 32 105 39 36 7 82 19 102 28 l38 16 92 -46 c51 -25 112 -57 134 -71 40
    -25 42 -25 47 -6 4 17 14 20 55 20 73 0 150 -17 179 -40 25 -20 27 -20 57 -2
    43 25 45 54 5 73 -18 7 -35 20 -37 28 -4 11 4 13 38 8 27 -3 51 -1 64 8 30 18
    149 28 179 14 23 -10 35 -6 106 36 45 26 81 52 81 56 0 5 -18 25 -40 45 -73
    68 -25 53 56 -17 74 -64 92 -73 109 -59 23 19 151 -5 186 -35 l29 -23 33 33
    c39 39 89 55 171 55 33 0 85 5 117 12 55 11 59 10 197 -37 l140 -49 40 36 c39
    35 57 43 155 68 28 8 68 29 95 52 45 38 49 39 101 33 30 -4 60 -9 68 -12 10
    -4 13 7 13 47 0 43 5 56 28 79 15 15 50 35 77 45 108 38 136 55 151 93 13 29
    13 45 5 79 -15 59 -15 62 15 89 23 22 25 25 9 31 -27 11 -186 -3 -234 -20 -24
    -9 -54 -16 -67 -16 -19 0 -22 5 -21 27 2 24 -5 32 -45 53 l-47 25 -29 -35 -29
    -34 -28 18 -27 18 38 26 39 26 -44 8 c-34 6 -61 3 -109 -12 -69 -22 -113 -17
    -159 15 -34 24 -28 38 9 19 69 -36 86 -36 124 0 39 37 107 56 200 56 47 0 55
    3 82 34 29 33 62 139 48 153 -3 4 -23 -2 -45 -11 -33 -15 -41 -16 -60 -5 -11
    8 -21 16 -21 19 0 3 39 25 86 48 80 40 88 42 104 27 24 -22 59 -11 107 31 34
    30 36 33 16 34 -27 0 -27 2 -8 39 14 27 13 29 -8 35 -12 3 -35 6 -51 6 -24 0
    -27 2 -16 15 7 9 29 15 53 15 38 0 39 1 24 18 -20 22 -21 32 -4 32 6 0 24 -14
    38 -32 l26 -32 40 32 c41 32 102 102 120 137 6 11 14 49 18 85 7 76 29 147 62
    209 17 32 23 60 23 105 0 68 -15 173 -32 226 -12 37 -145 183 -188 205 -56 29
    -455 78 -518 64 -46 -10 -61 -27 -73 -80 -13 -56 -53 -109 -82 -109 -13 0 -43
    14 -67 30 -25 17 -64 42 -87 58 -23 15 -43 32 -43 38 0 19 98 142 137 170 74
    54 76 66 37 200 -53 183 -107 264 -223 333 -39 23 -91 66 -119 97 l-50 57 -71
    -19 c-58 -14 -76 -16 -89 -6 -48 34 -74 24 -83 -32 -9 -55 -19 -55 -19 1 0 72
    39 100 105 74 12 -5 45 -1 85 10 36 11 71 19 79 19 7 0 36 -25 64 -55 50 -55
    50 -55 109 -55 33 0 68 -5 79 -11 20 -10 20 -10 3 23 -9 18 -48 82 -86 142
    -98 151 -120 203 -116 268 3 48 6 54 35 69 30 15 31 17 15 31 -9 9 -32 23 -49
    32 -18 9 -33 20 -33 24 0 5 -22 33 -48 63 -27 29 -54 70 -61 89 -6 19 -22 51
    -35 70 -13 19 -26 44 -30 55 -3 11 -39 44 -80 74 -55 41 -101 64 -180 91 -119
    41 -159 66 -164 101 -2 13 -22 43 -43 68 -45 51 -69 120 -69 201 0 45 -6 65
    -26 95 -14 21 -35 63 -46 94 -31 82 -71 287 -70 356 3 122 -80 276 -176 330
    -18 10 -47 34 -63 53 -27 30 -33 33 -54 24 -20 -9 -23 -9 -18 3 3 8 11 15 19
    15 21 0 17 19 -20 85 -35 63 -66 83 -131 84 -38 1 -171 81 -203 122 -21 26
    -29 29 -82 29 -51 0 -61 -3 -79 -26 -12 -15 -21 -33 -21 -40 0 -18 -73 -64
    -101 -64 -12 0 -38 10 -58 22 -19 12 -45 21 -57 20 -27 -4 -217 34 -272 54
    -23 9 -40 20 -36 25 10 16 94 9 144 -12 l48 -21 30 22 c17 12 50 25 75 27 40
    5 47 9 52 33 8 35 68 106 108 126 26 14 36 14 69 3 31 -10 43 -10 64 1 32 17
    104 79 104 89 0 4 -27 19 -59 33 l-60 26 -3 59 -3 58 -36 3 c-34 3 -81 -17
    -196 -84 -47 -27 -50 -11 -8 39 55 66 84 79 180 81 73 1 86 4 124 31 65 45
    131 126 131 162 0 17 6 36 14 42 8 6 17 32 20 56 6 36 16 52 52 83 54 48 116
    148 112 182 -1 14 17 63 40 110 40 77 42 88 36 137 -5 45 -2 61 23 115 17 35
    60 104 97 155 36 51 66 101 66 113 0 12 -7 52 -15 89 -17 72 -34 95 -109 145
    -45 30 -76 33 -111 10 -21 -14 -30 -14 -54 -4 -24 10 -35 8 -68 -8 l-39 -20
    -142 23 -143 23 -57 -23 -57 -23 -65 25 c-118 46 -125 47 -174 33 -25 -7 -46
    -17 -46 -22 0 -4 -12 -14 -27 -20 -16 -7 -36 -18 -45 -26 -12 -10 -18 -11 -18
    -4 0 21 -29 13 -86 -25 -47 -31 -67 -38 -120 -41 -36 -3 -61 -8 -58 -14 3 -5
    3 -20 0 -33 -14 -54 -186 -107 -231 -71 -17 13 -18 17 -5 26 8 5 34 7 57 4 40
    -6 42 -5 67 34 14 23 31 44 39 49 15 10 77 94 77 105 0 5 -14 1 -31 -7 -19
    -10 -41 -14 -59 -10 -24 5 -38 -2 -94 -50 -59 -50 -66 -53 -66 -33 0 54 75
    128 130 128 14 0 49 14 79 30 30 17 56 30 59 30 2 0 2 -13 -1 -30 -12 -55 10
    -39 64 48 67 108 68 111 35 84 -23 -17 -31 -19 -52 -10 -17 8 -31 8 -48 0 -18
    -8 -37 -6 -87 12 -66 24 -144 66 -121 66 8 0 41 -7 75 -16 83 -22 117 -10 117
    41 0 19 -4 35 -10 35 -5 0 -10 5 -10 10 0 6 4 10 10 10 17 0 107 69 124 95 9
    14 60 57 113 97 64 48 110 93 137 131 30 42 62 71 119 107 113 73 146 134 109
    204 -28 53 -28 72 3 112 14 18 29 44 35 58 8 21 6 27 -11 37 -16 8 -25 7 -40
    -7 -18 -16 -20 -16 -37 -1 -20 19 -132 25 -132 8z m-630 -1558 c-30 -53 -153
    -213 -159 -208 -5 5 113 188 143 223 31 35 40 26 16 -15z m-185 -1390 c9 -21
    31 -50 50 -66 39 -32 44 -52 16 -67 -33 -17 -40 -12 -47 38 -4 26 -15 64 -25
    84 -20 39 -22 48 -14 48 3 0 12 -17 20 -37z m1595 -536 c0 -2 -10 -12 -22 -23
    l-23 -19 19 23 c18 21 26 27 26 19z"
        />
        <path
          d="M3128 9359 c-44 -59 -168 -157 -231 -182 -85 -34 -94 -51 -62 -110
    30 -58 11 -65 -35 -14 l-30 32 6 -27 c8 -34 -26 -57 -47 -32 -7 9 -22 20 -33
    24 -18 6 -25 -1 -48 -48 -34 -67 -38 -128 -8 -137 15 -5 20 -15 20 -42 0 -32
    -2 -35 -25 -31 -32 7 -31 1 3 -32 19 -18 45 -30 74 -34 25 -4 53 -13 63 -20
    22 -16 14 -46 -13 -46 -18 0 -122 -98 -122 -115 1 -6 17 -28 36 -50 l35 -40
    17 25 c23 35 74 86 92 92 12 4 13 9 3 27 -30 49 -5 73 47 46 31 -16 32 -16 26
    3 -4 12 1 25 13 36 16 14 23 15 39 5 28 -18 47 -6 72 46 14 30 27 44 36 41 28
    -11 37 33 20 95 -15 53 -15 61 -1 82 8 13 13 32 9 41 -5 12 0 19 14 23 17 4
    20 11 15 34 -4 24 3 37 45 85 45 51 49 58 36 72 -9 9 -13 24 -10 36 18 65 18
    95 0 120 -24 33 -29 32 -56 -5z"
        />
        <path
          d="M2531 8381 c-20 -15 -29 -17 -49 -8 -18 8 -25 8 -32 -3 -4 -7 -17
    -11 -29 -7 -17 4 -25 -3 -42 -35 -20 -40 -20 -41 -2 -51 30 -17 83 -77 83 -95
    -1 -9 -7 -28 -15 -42 -12 -22 -11 -27 2 -42 15 -16 15 -20 -2 -38 -14 -15 -16
    -29 -11 -62 4 -24 2 -62 -5 -89 -10 -38 -10 -62 0 -118 l13 -71 33 0 c18 0 40
    -3 49 -6 13 -5 14 -1 2 33 -36 103 -36 97 0 144 l33 44 -24 41 -25 42 25 16
    c21 14 23 19 14 45 -8 23 -7 35 5 53 9 13 25 50 36 83 15 44 17 61 8 72 -9 11
    -5 20 15 41 36 37 34 47 -12 60 -47 15 -41 15 -70 -7z"
        />
        <path
          d="M3091 8369 c-23 -30 -23 -31 -6 -63 14 -25 15 -37 7 -52 -9 -16 -7
    -28 9 -53 22 -37 18 -51 -10 -34 -11 7 -23 7 -33 2 -12 -7 -28 5 -68 51 -29
    33 -56 57 -61 54 -17 -10 -9 -40 16 -64 28 -26 34 -56 10 -46 -11 4 -15 -2
    -15 -22 0 -54 -15 -56 -48 -5 -28 43 -32 45 -32 23 0 -15 5 -31 12 -38 9 -9 7
    -12 -10 -12 -23 0 -30 -14 -12 -25 5 -3 13 -20 18 -37 6 -26 15 -33 52 -44 25
    -8 45 -14 46 -14 0 0 -1 13 -4 29 -5 33 12 48 33 31 8 -6 19 -9 25 -5 6 4 10
    -4 10 -19 0 -16 6 -26 14 -26 19 0 73 -46 65 -55 -4 -3 -18 0 -33 6 -62 29
    -55 -44 11 -101 18 -17 38 -30 43 -30 6 0 10 -7 10 -15 0 -8 4 -15 9 -15 5 0
    25 7 44 15 20 8 46 15 58 15 18 0 21 -5 17 -35 -2 -19 -3 -35 -1 -35 1 0 14
    21 27 48 l24 47 13 -27 c11 -25 16 -27 51 -22 21 4 38 4 38 0 0 -3 -18 -17
    -39 -31 -26 -17 -41 -36 -45 -56 -3 -16 -9 -42 -12 -59 -10 -48 18 -37 80 32
    56 61 65 81 46 93 -16 10 -4 22 27 28 26 5 63 50 63 75 0 18 -52 20 -97 5 -43
    -15 -47 -14 -84 6 -25 15 -47 20 -61 16 -17 -6 -20 -4 -15 9 5 11 -2 25 -18
    40 -14 13 -28 38 -31 57 -4 19 -11 40 -17 47 -7 9 -6 20 3 36 8 16 11 54 9
    114 l-4 91 -50 51 c-27 27 -52 50 -55 49 -3 0 -16 -14 -29 -30z"
        />
        <path
          d="M3080 7623 l-30 -28 40 -41 c35 -38 40 -40 53 -25 24 28 27 50 9 61
    -15 8 -15 11 -2 20 13 9 12 12 -4 25 -27 20 -31 19 -66 -12z"
        />
        <path
          d="M2371 7573 c-29 -35 -41 -73 -23 -73 15 0 72 66 72 82 0 32 -19 28
    -49 -9z"
        />
        <path
          d="M2970 7172 c-27 -24 -41 -44 -39 -55 3 -16 10 -13 45 17 38 32 63 76
    44 76 -4 0 -27 -17 -50 -38z"
        />
        <path
          d="M3213 7150 c-13 -5 -23 -18 -23 -29 0 -17 -4 -19 -23 -12 -21 8 -23
    7 -20 -14 3 -12 0 -26 -6 -29 -17 -11 -13 -26 7 -26 9 0 39 -13 66 -29 46 -27
    52 -28 77 -16 29 15 39 11 39 -16 0 -10 -19 -28 -46 -44 -42 -25 -75 -68 -62
    -81 4 -3 24 4 47 15 50 26 61 26 61 2 0 -13 -21 -30 -69 -55 -76 -40 -93 -43
    -111 -21 -33 40 -67 2 -36 -40 14 -19 15 -19 50 -2 20 9 53 17 74 17 54 0 107
    17 141 45 36 31 41 31 41 0 0 -33 18 -32 60 6 20 17 27 28 17 25 -15 -5 -18
    -1 -15 17 2 15 10 22 25 21 16 0 23 7 27 30 9 41 -25 71 -106 93 -54 15 -64
    22 -100 72 -60 83 -70 89 -115 71z"
        />
        <path
          d="M2750 7016 c-51 -14 -60 -39 -22 -63 21 -15 22 -14 22 8 0 13 12 33
    28 46 15 12 25 22 22 22 -3 -1 -25 -6 -50 -13z"
        />
        <path
          d="M3415 6496 c-89 -83 -90 -84 -71 -104 18 -21 18 -21 -7 -27 -38 -10
    -59 -40 -61 -84 -1 -41 24 -81 51 -81 16 0 186 344 181 367 -2 8 -40 -21 -93
    -71z"
        />
        <path
          d="M3185 6516 c-16 -21 -25 -44 -23 -58 3 -19 8 -14 32 30 33 64 29 77
    -9 28z"
        />
        <path
          d="M3172 6304 c-24 -19 -52 -34 -62 -34 -38 0 -68 -22 -74 -53 -4 -18
    -2 -33 5 -37 7 -5 4 -21 -11 -49 -39 -77 -20 -81 44 -8 46 52 62 64 73 56 17
    -15 16 -29 -3 -50 -15 -17 -15 -20 5 -45 27 -35 27 -50 -4 -70 -37 -24 -31
    -73 8 -58 8 3 19 16 25 28 7 15 26 29 54 37 71 23 75 45 33 175 -19 59 -35
    113 -35 120 0 24 -15 21 -58 -12z"
        />
        <path
          d="M3761 6051 l-33 -50 11 -67 c23 -128 50 -159 124 -137 26 7 33 14 35
    41 2 20 -1 32 -8 32 -8 0 -10 11 -6 35 5 27 3 37 -9 41 -10 4 -15 19 -15 43 0
    41 -30 105 -51 109 -8 1 -29 -20 -48 -47z"
        />
        <path
          d="M2926 5514 l-90 -46 -53 15 -54 14 -33 -72 c-36 -77 -58 -98 -91 -84
    -28 11 -89 11 -119 -1 -30 -11 -39 -30 -57 -115 -14 -69 -24 -91 -51 -122 -11
    -12 -23 -37 -27 -55 -6 -30 -13 -35 -81 -61 -60 -24 -78 -27 -93 -17 -15 9
    -23 8 -38 -6 -27 -25 -24 -39 17 -64 24 -16 44 -21 65 -16 22 4 29 2 29 -9 0
    -8 -4 -15 -9 -15 -5 0 -27 -17 -48 -37 l-38 -37 30 -6 c24 -4 29 -10 27 -28
    -2 -12 1 -22 6 -22 11 0 67 -118 60 -126 -4 -3 -32 15 -63 41 -64 51 -92 65
    -136 65 -42 0 -36 18 13 40 l42 18 -39 8 c-38 7 -41 5 -88 -46 l-49 -53 71
    -65 c70 -63 121 -133 121 -165 0 -13 10 -17 44 -17 38 0 51 -6 94 -45 27 -25
    55 -45 60 -45 6 0 24 8 40 17 28 16 30 16 58 -6 l29 -23 5 23 c7 28 30 37 31
    12 0 -10 6 0 13 22 7 23 21 44 34 51 35 17 36 21 13 53 -26 36 -20 78 14 91
    13 5 35 25 50 44 15 20 32 36 38 36 6 0 32 -22 57 -48 34 -36 49 -60 53 -89 4
    -22 20 -58 37 -81 26 -37 33 -41 49 -30 41 25 71 -39 52 -108 -11 -41 0 -59
    29 -48 8 3 40 8 71 11 47 5 59 10 67 28 9 20 16 22 59 17 30 -3 59 -14 76 -28
    16 -13 33 -24 39 -24 6 0 16 -7 22 -16 11 -14 18 -10 62 29 48 44 49 46 49
    103 0 58 0 58 40 76 47 21 86 23 104 6 28 -28 92 94 67 127 -10 14 -16 15 -39
    4 -15 -7 -29 -10 -32 -7 -3 3 -1 27 5 53 12 53 5 109 -16 126 -19 16 -18 33 5
    53 18 17 21 16 59 -19 47 -41 59 -80 42 -131 -9 -27 -9 -42 0 -65 17 -41 28
    -26 39 55 9 64 8 74 -21 154 -39 109 -56 126 -114 119 -25 -2 -61 -16 -82 -29
    -61 -40 -72 -42 -72 -9 0 22 15 41 71 91 62 55 70 66 64 89 -9 37 -24 49 -17
    14 l5 -28 -31 29 c-17 15 -33 40 -37 55 -4 14 -29 55 -56 91 -38 48 -49 70
    -44 87 4 19 0 25 -21 33 -25 10 -25 12 -19 74 7 60 6 65 -17 80 -42 27 -108
    46 -186 51 -76 5 -78 5 -166 -41z"
        />
      </g>
    </svg>
  );
};
