export const UAESvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke={stroke}
      >
        <path
          d="M9695 9007 c-66 -149 -178 -364 -201 -388 -23 -24 -25 -31 -14 -44
        10 -12 6 -22 -26 -57 -21 -24 -47 -46 -58 -50 -45 -14 -49 -7 -18 25 21 22 33
        46 38 77 l7 45 -102 -95 c-107 -99 -134 -120 -164 -120 -9 0 -99 -69 -199
        -152 -99 -84 -193 -163 -207 -175 -25 -20 -25 -22 -9 -40 32 -35 13 -59 -74
        -93 -44 -16 -82 -30 -84 -30 -3 0 3 14 11 30 15 28 14 31 -2 37 -10 3 -21 8
        -25 10 -4 2 -20 -16 -36 -39 -19 -27 -53 -56 -91 -78 -69 -40 -80 -57 -51 -77
        20 -14 20 -15 -11 -54 -17 -21 -34 -51 -38 -65 l-7 -26 -23 20 -24 19 -28 -31
        c-17 -20 -30 -49 -34 -81 -11 -68 -65 -113 -80 -65 -3 11 -9 20 -13 20 -12 -1
        -137 -168 -140 -189 -3 -17 -9 -19 -56 -14 l-52 5 -48 -56 c-27 -31 -78 -78
        -115 -105 -78 -58 -316 -297 -325 -326 -5 -16 -29 -27 -103 -50 -89 -26 -105
        -34 -168 -90 -38 -34 -116 -95 -172 -134 -99 -70 -120 -95 -88 -107 16 -6 20
        -51 7 -70 -4 -6 -20 -15 -37 -21 -26 -8 -30 -15 -33 -52 -3 -33 -7 -41 -18
        -37 -9 3 -18 6 -20 6 -2 0 -4 15 -4 34 0 28 -5 36 -30 46 -27 11 -37 9 -93
        -15 -82 -36 -107 -52 -107 -70 0 -10 11 -15 35 -15 42 0 44 -8 10 -40 -14 -13
        -25 -29 -25 -35 0 -7 -9 -20 -20 -30 -24 -21 -26 -58 -5 -75 19 -16 10 -30
        -21 -30 -20 0 -24 -5 -24 -30 0 -25 -6 -32 -36 -45 -33 -13 -36 -18 -31 -44 5
        -22 -1 -38 -23 -67 -16 -22 -30 -51 -30 -66 0 -26 -3 -28 -40 -28 -46 0 -49
        -9 -15 -53 l24 -33 -37 -62 c-20 -34 -42 -62 -49 -62 -7 0 -13 -7 -13 -15 0
        -10 -10 -15 -31 -15 -17 0 -38 -4 -48 -10 -23 -12 -69 11 -133 64 -55 46 -64
        79 -56 196 3 29 0 35 -17 35 -23 0 -85 -96 -85 -132 0 -18 -6 -23 -26 -23 -40
        0 -64 -18 -64 -47 0 -25 2 -25 49 -21 49 5 51 4 98 -44 29 -30 79 -66 126 -89
        60 -31 77 -44 77 -61 0 -12 7 -29 16 -39 21 -24 0 -59 -36 -59 -19 0 -35 -11
        -55 -39 l-30 -39 -101 -4 c-90 -3 -106 -6 -142 -31 -46 -30 -112 -36 -173 -16
        -48 16 -139 107 -139 138 0 13 -7 32 -16 42 -15 17 -18 17 -60 -4 -51 -25 -60
        -54 -16 -59 21 -2 28 -9 30 -30 2 -16 9 -28 17 -28 7 0 20 -11 29 -25 22 -34
        4 -65 -40 -65 -18 0 -46 -12 -70 -30 -21 -16 -50 -30 -63 -30 -13 0 -45 -12
        -70 -27 -33 -20 -66 -30 -116 -34 -76 -8 -154 -28 -176 -45 -10 -8 -37 -9 -93
        -2 -54 7 -82 6 -89 -1 -6 -6 -44 -11 -84 -11 -65 0 -77 3 -96 24 l-22 23 -200
        -13 -199 -13 -43 25 c-23 13 -48 35 -54 47 -7 12 -13 23 -14 25 -1 1 -39 -7
        -84 -18 l-81 -21 -41 23 c-32 18 -42 20 -48 9 -7 -11 -25 -11 -97 -2 -149 18
        -183 33 -223 94 -57 90 -142 127 -149 65 -3 -23 -13 -30 -73 -53 -77 -30 -99
        -28 -99 8 0 33 -22 49 -81 58 -39 6 -58 4 -84 -8 -30 -14 -39 -15 -77 -3 -24
        8 -54 13 -68 12 -19 -2 -30 5 -44 28 -10 17 -23 29 -29 26 -50 -21 -91 -28
        -129 -22 -37 6 -46 11 -55 36 -15 41 -60 41 -105 1 -35 -30 -58 -31 -58 -1 0
        11 -19 32 -47 51 -27 18 -64 47 -84 66 -31 30 -42 34 -67 28 -26 -5 -33 -14
        -54 -73 l-23 -67 -81 -29 c-76 -27 -83 -32 -103 -73 -19 -40 -24 -43 -59 -43
        -32 0 -54 -11 -127 -66 -48 -37 -90 -75 -92 -85 -4 -13 -14 -19 -34 -19 -16 0
        -57 -14 -90 -31 -58 -29 -66 -30 -143 -24 -104 8 -221 8 -268 1 -33 -5 -38 -3
        -57 29 -20 35 -21 35 -91 35 -45 0 -82 6 -102 16 -19 10 -42 14 -60 10 -43 -9
        -94 14 -188 83 l-85 62 -17 142 -17 142 21 22 c19 20 20 26 9 50 -11 23 -10
        29 3 39 12 8 16 29 16 84 0 71 -1 73 -40 112 -21 22 -44 37 -49 34 -5 -4 -13
        -42 -17 -86 -6 -69 -9 -80 -25 -80 -14 0 -19 7 -19 25 0 14 -12 54 -26 91 -32
        78 -49 73 -49 -16 0 -32 -2 -60 -5 -63 -3 -2 -22 -8 -44 -11 l-38 -7 8 68 c13
        103 6 241 -15 281 l-18 34 -13 -31 c-10 -24 -10 -34 -1 -45 10 -11 9 -21 -3
        -45 -9 -16 -16 -48 -16 -70 0 -38 -3 -42 -48 -65 l-48 -24 -43 -139 -43 -138
        882 -1540 c486 -847 885 -1543 889 -1547 3 -4 1096 -282 2428 -619 l2422 -611
        275 173 c235 147 277 177 285 203 6 17 13 114 16 216 l6 185 75 305 75 305
        133 140 c129 137 135 144 254 355 l122 216 70 23 c90 29 94 37 84 193 l-6 117
        37 45 37 45 9 141 c7 100 16 154 30 186 10 25 19 49 19 54 0 4 -36 34 -80 66
        -85 61 -89 68 -74 141 6 30 11 32 291 128 l286 98 114 -57 114 -56 62 16 c43
        11 81 14 123 10 l62 -6 52 65 51 65 -36 59 c-23 37 -48 64 -66 71 -35 15 -45
        44 -26 74 8 12 17 33 19 47 3 27 6 27 -177 8 -84 -8 -92 -7 -120 13 -95 69
        -114 84 -115 89 0 3 43 54 96 113 l96 107 10 80 c9 77 9 80 -20 132 -28 49
        -30 60 -36 201 l-6 148 62 144 61 144 -56 217 -57 218 67 61 68 62 174 46 c95
        26 176 45 179 42 4 -5 92 -207 92 -213 0 -3 -73 -23 -85 -23 -6 0 -19 13 -28
        30 -16 26 -20 28 -42 18 l-25 -11 82 -160 82 -160 -56 -51 -56 -51 17 -60 c17
        -58 19 -60 57 -67 85 -15 106 -10 149 32 l40 39 46 -29 46 -28 56 46 c51 42
        58 53 67 102 6 30 13 62 16 71 3 9 44 43 92 74 78 52 92 65 124 124 34 64 35
        68 26 122 -9 53 -8 59 19 98 27 41 29 42 76 38 26 -2 47 -1 47 3 0 4 -11 32
        -24 61 l-23 54 34 150 c29 127 35 172 37 291 l2 142 46 73 c51 81 52 111 4
        117 -14 2 -26 6 -26 10 0 14 39 66 65 86 l27 22 -21 42 -22 43 21 107 c20 105
        25 197 14 238 -7 27 -95 56 -145 48 -32 -5 -38 -2 -62 30 l-28 35 -47 -18
        c-40 -16 -47 -23 -50 -50 -2 -21 -15 -40 -40 -61 -20 -16 -40 -30 -44 -30 -5
        0 -26 11 -47 24 l-39 25 -7 85 -7 85 56 108 c67 131 82 203 92 446 l7 168 -77
        79 -77 79 -24 -52z"
        />
        <path
          d="M2238 5746 c-23 -32 -23 -63 3 -139 24 -73 35 -87 44 -58 4 15 15 21
        35 21 19 0 36 10 54 31 30 36 29 49 -13 119 -24 40 -32 45 -67 48 -32 2 -42
        -3 -56 -22z"
        />
        <path
          d="M4730 5365 c-13 -14 -33 -25 -44 -25 -12 -1 -32 -7 -46 -15 -14 -8
        -52 -14 -87 -15 -52 0 -76 -7 -150 -43 -114 -55 -113 -55 -106 -72 4 -11 23
        -15 79 -15 70 0 73 -1 91 -30 20 -34 25 -36 110 -45 77 -8 112 10 111 57 -1
        19 6 36 17 44 25 19 39 18 59 -5 15 -16 15 -19 1 -31 -24 -20 -18 -40 13 -40
        16 0 37 -7 48 -15 26 -20 34 -19 34 5 0 19 1 19 33 4 31 -15 35 -15 70 6 35
        21 38 26 35 63 -3 40 -3 41 -57 53 -107 24 -197 84 -126 84 31 0 32 10 2 38
        -31 29 -58 28 -87 -3z"
        />
      </g>
    </svg>
  );
};
