import { DataFact, DataFactType } from "../../Components/DataFact/DataFact";
import { useTranslate } from "../../hooks/useTranslation";
import { dataFactsDummy } from "./dataFactsDummy";

type DataFactsPropsType = {
  className?: string;
  headline?: string;
  text?: string;
  dataFacts?: DataFactType[];
};

export const DataFacts = ({
  className,
  headline,
  text,
  dataFacts = dataFactsDummy,
}: DataFactsPropsType) => {
  const translate = useTranslate();

  return (
    <div className={`${className}`}>
      <h2 className="heading-2 xl:w-3/5 md:ml-auto md:text-right">
        {headline}
      </h2>
      <h3 className="heading-3">{text}</h3>
      <div className="mt-20 relative grid md:grid-cols-6 xl:grid-cols-12">
        {dataFacts.map((dataFact) => {
          return (
            <DataFact
              number={dataFact.number}
              className="col-span-3"
              text={translate(dataFact.text)}
              symbol={dataFact.symbol}
            />
          );
        })}
      </div>
    </div>
  );
};
