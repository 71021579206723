export const carouselDummy: { text: string; id: string }[] = [
  { text: "Amsterdam", id: "netherlands" },
  { text: "Munich", id: "germany" },
  { text: "Madrid", id: "spain" },
  { text: "Zurich", id: "switzerland" },
  { text: "Singapore", id: "singapore" },
  { text: "Dubai", id: "uae" },
  { text: "Berlin", id: "germany" },
  { text: "Vienna", id: "austria" },
  { text: "Barcelona", id: "spain" },
  { text: "London", id: "uk" },
];
