import logo from "../../Assets/logo.png";

export const Footer = () => {
  return (
    <div className="p-5 md:p-40 mt-20 md:mt-40 bg-dark text-white flex-col md:flex-row flex items-center justify-between">
      <div className="flex items-center border-white border w-fit rounded-lg">
        <img
          className="w-9 bg-dark p-1 rounded-lg  z-10"
          src={logo}
          alt="Logo"
        />
        <h1 className={`logo pl-4 text-white -ml-3 `}>MILLERIO</h1>
      </div>
      <p className="text-white md:text-left text-center mt-5 md:mt-0" >Website under development - Preview version</p>
    </div>
  );
};
