import { useState, useEffect } from "react";
import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import { Button } from "../../Components/Button/Button";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pages = [
    { label: "Home", link: "/" },
    { label: "AI", link: "/ai" },
    { label: "Development", link: "/development" },
    { label: "Magnet Forms", link: "/forms" },
  ];

  return (
    <div
      className={`fixed w-full border-b border-white flex gap-5 md:flex-row flex-col justify-between z-50 items-center p-5 md:p-10 transition-colors duration-300 ${
        isScrolled ? "bg-dark" : "bg-transparent"
      }`}
    >
      <div className="flex items-center">
        <img
          className="w-9 bg-dark p-1 rounded-lg z-10"
          src={logo}
          alt="Logo"
        />
        <h1
          className={`logo pl-4 -ml-3 ${
            isScrolled ? "text-white" : "text-dark"
          }`}
        >
          MILLERIO
        </h1>
      </div>
      <p className={`${isScrolled ? "text-white" : "text-dark"} `}>
        Website under development - Preview version
      </p>
      {/* <div className="flex gap-10">
        {pages.map((page) => (
          <Link
            className={`body-2 hover:underline ${
              isScrolled ? "text-white" : ""
            } `}
            to={page.link}
          >
            {page.label}
          </Link>
        ))}
      </div> */}
    </div>
  );
};
