import { carouselDummy } from "./carouselConfig";
import CarouselItem from "./CarouselItem";

import { getCountrySvg } from "../../Utils/countriesSvgs/getCountrySvg";

import "./Carousel.scss";
import { getThemeColor } from "../../Utils/tailwind/tailwindUtils";

export const Carousel = ({ className }: { className?: string }) => {
  const carouselItem = carouselDummy.map((item) => {
    return (
      <CarouselItem
        text={item.text}
        image={getCountrySvg(item.id, getThemeColor("blue"), "200px", "200px")}
      />
    );
  });
  return (
    <div className={`carousel-container h-80 ${className} `}>
      <div className="carousel-track">
        {carouselItem}
        {carouselItem}
      </div>
    </div>
  );
};
