import { useState } from "react";

import { ParticlesBackground } from "./Components/ParticlesBackground/ParticlesBackground";

import { Home } from "./pages/Home/Home";
import { Header } from "./modules/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./modules/Footer/Footer";
import { AIPage } from "./pages/AI/AIPage";
import { DevelopmentPage } from "./pages/Development/DevelopmentPage";
import { FormsPage } from "./pages/FormsPage/FormsPage";

function App() {
  return (
    <BrowserRouter>
      <div>
        <ParticlesBackground />
        <div className="relative">
          <Header />
          <Routes>
            <Route path="/" element={<AIPage />} />
            {/* <Route path="/" element={<Home />} />
            <Route path="/development" element={<DevelopmentPage />} />
            <Route path="/forms" element={<FormsPage />} /> */}
          </Routes>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
