import { ReactNode } from "react";

type CardPropsType = {
  children: ReactNode;
  className?: string;
};

export const Card = ({ children, className }: CardPropsType) => {
  return (
    <div className={`border-l border-grey border-t p-5 rounded-tl-2xl ${className}`}>
      {children}
    </div>
  );
};
