export const NetherlandsSvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke={stroke}
      >
        <path
          d="M7390 10215 c-29 -16 -62 -24 -93 -25 -70 0 -207 -38 -244 -66 -30
    -23 -45 -64 -24 -64 5 0 13 -9 16 -20 8 -27 23 -25 55 5 20 19 45 27 124 39
    70 11 105 21 120 35 12 11 39 28 61 37 54 22 95 52 95 70 0 22 -59 16 -110
    -11z"
        />
        <path
          d="M8571 10076 c-12 -14 -34 -16 -140 -14 l-126 3 -145 -63 c-113 -49
    -163 -65 -225 -73 -44 -5 -105 -14 -136 -19 -30 -6 -75 -10 -98 -10 -31 0 -58
    -9 -90 -29 -84 -51 -106 -55 -202 -37 -47 9 -107 16 -134 16 l-49 0 29 -30
    c26 -27 27 -30 9 -30 -11 0 -29 7 -40 15 -28 22 -34 18 -34 -19 0 -43 29 -66
    82 -66 l38 0 -6 -40 c-7 -44 -11 -41 77 -59 50 -11 61 -18 95 -61 22 -27 50
    -51 64 -55 22 -6 23 -8 7 -16 -21 -12 -70 19 -89 57 -9 19 -30 32 -72 46 -64
    21 -86 19 -86 -9 -1 -10 -12 4 -27 31 l-25 50 -14 -25 c-10 -18 -13 -20 -14
    -6 0 21 -28 23 -37 1 -3 -8 5 -26 18 -41 l24 -26 -25 6 c-14 4 -30 7 -37 7 -7
    0 -15 17 -18 38 -4 20 -11 57 -17 82 -8 36 -7 52 5 81 25 60 17 63 -145 56
    -147 -7 -206 -19 -388 -75 -91 -29 -434 -199 -541 -270 -32 -21 -63 -32 -110
    -37 -75 -8 -109 -30 -163 -103 -20 -28 -75 -77 -128 -115 -51 -37 -103 -75
    -115 -84 -31 -25 -52 -100 -63 -224 -11 -121 -19 -147 -60 -199 -37 -46 -41
    -107 -10 -150 30 -42 11 -45 -32 -3 -21 20 -40 34 -43 31 -7 -7 59 -142 86
    -176 14 -19 16 -35 12 -95 -4 -54 -2 -81 9 -102 9 -17 18 -51 22 -76 6 -42 5
    -47 -14 -52 -20 -5 -21 -10 -15 -62 l7 -56 -51 -47 c-28 -26 -51 -54 -51 -62
    1 -27 24 -58 70 -94 49 -39 98 -47 120 -21 17 21 86 19 118 -3 78 -54 177 -67
    214 -30 13 13 30 21 40 20 9 -2 30 4 47 12 37 20 51 20 51 1 0 -23 34 -35 100
    -35 56 0 60 -1 51 -19 -8 -13 -25 -20 -65 -23 -47 -4 -58 -9 -83 -38 -15 -18
    -31 -42 -35 -52 -4 -10 -25 -44 -47 -76 l-39 -58 -7 -200 -7 -199 39 -54 c21
    -30 63 -73 93 -97 l55 -43 120 -2 c112 -2 128 0 235 33 63 20 153 41 199 48
    87 13 87 14 144 81 15 17 17 17 56 -4 23 -12 40 -29 40 -37 0 -8 -17 -29 -38
    -47 -34 -29 -43 -32 -136 -38 -91 -5 -184 -23 -242 -46 -18 -7 -23 -17 -23
    -43 0 -19 -6 -37 -12 -40 -10 -5 -10 -7 0 -12 20 -9 57 -101 72 -175 13 -64
    12 -69 -16 -150 -56 -162 -204 -347 -304 -380 -19 -7 -54 -29 -78 -50 -24 -21
    -55 -39 -70 -41 -17 -2 -34 -15 -47 -34 -11 -17 -37 -44 -58 -59 -43 -30 -69
    -81 -88 -168 -11 -49 -19 -64 -53 -92 -35 -28 -60 -37 -173 -61 l-132 -27 -57
    49 c-49 41 -66 50 -108 54 -49 5 -52 7 -79 57 -34 64 -69 78 -169 70 -38 -3
    -94 -2 -126 3 -51 8 -62 14 -102 60 -37 41 -51 50 -78 50 -19 0 -47 -8 -62
    -17 -24 -14 -31 -14 -44 -3 -9 7 -15 20 -14 29 2 12 -6 17 -32 19 -43 4 -94
    41 -85 62 3 8 29 26 56 39 37 17 58 36 80 70 16 26 40 53 54 59 14 6 25 20 25
    30 0 25 93 112 120 112 28 0 25 26 -7 64 -16 17 -35 43 -44 56 l-17 25 5 -27
    c6 -30 -20 -124 -42 -152 -12 -16 -17 -17 -30 -6 -9 8 -26 31 -37 53 -21 42
    -15 57 25 57 13 0 17 9 17 41 0 25 9 55 21 75 l20 34 -26 24 c-20 18 -30 43
    -42 103 -8 43 -21 85 -28 93 -18 21 -55 138 -55 172 0 16 7 43 16 60 15 29 18
    30 64 25 29 -4 52 -1 59 6 15 15 81 -17 88 -43 4 -16 14 -20 50 -20 38 0 55 8
    115 52 70 52 88 77 88 124 0 39 42 74 87 74 32 0 42 5 55 28 15 26 15 28 -8
    55 -13 16 -24 41 -24 57 0 58 -17 77 -75 85 -30 3 -59 13 -66 21 -6 8 -24 14
    -40 14 -22 0 -32 -7 -44 -30 -12 -24 -22 -30 -48 -30 -46 0 -72 22 -87 74 -12
    43 -10 140 6 228 9 52 -34 167 -91 242 -25 34 -50 82 -59 116 -16 55 -18 58
    -54 64 -97 16 -150 1 -186 -54 -14 -22 -49 -53 -77 -69 -42 -24 -56 -27 -82
    -20 -18 4 -49 11 -70 14 -56 9 -117 67 -117 111 0 19 5 42 11 52 8 13 9 28 2
    48 -11 34 -47 42 -113 24 -59 -16 -80 -54 -80 -150 0 -77 -46 -328 -74 -409
    -9 -24 -31 -69 -50 -100 -28 -47 -34 -69 -39 -136 -3 -44 -13 -101 -22 -127
    -14 -39 -15 -62 -7 -135 8 -79 7 -90 -10 -112 -14 -19 -18 -41 -18 -106 0 -48
    -6 -100 -15 -125 -8 -23 -20 -72 -26 -108 -5 -37 -23 -109 -38 -162 -16 -52
    -32 -122 -35 -155 -13 -105 -45 -216 -108 -370 -65 -157 -291 -596 -361 -700
    -61 -91 -151 -207 -182 -235 -15 -14 -53 -56 -84 -95 -31 -38 -102 -115 -159
    -169 -58 -57 -109 -115 -119 -137 -10 -24 -24 -39 -34 -39 -31 0 -69 -23 -69
    -41 0 -11 33 -38 92 -74 51 -32 111 -72 133 -89 67 -54 161 -115 188 -121 14
    -4 35 -17 47 -30 l21 -23 -58 13 c-32 6 -75 22 -95 34 l-38 23 0 -29 0 -28
    -40 45 c-22 25 -53 62 -67 84 -34 48 -69 63 -94 40 -16 -15 -19 -15 -19 -2 0
    16 -37 68 -48 68 -25 -1 -31 -18 -23 -68 7 -46 6 -52 -10 -52 -31 0 -56 49
    -51 100 2 25 9 50 14 57 11 14 -24 53 -48 53 -73 0 -99 -132 -48 -244 23 -50
    24 -59 13 -90 -11 -32 -10 -37 14 -66 40 -47 47 -96 22 -153 -6 -14 -2 -17 23
    -17 17 0 35 -6 39 -14 9 -15 73 -36 111 -36 37 0 198 -109 221 -150 30 -54 60
    -75 136 -100 39 -13 108 -42 155 -65 47 -23 110 -50 140 -59 30 -10 75 -25
    100 -33 159 -55 198 -53 291 13 35 24 77 52 93 62 16 9 44 30 62 46 l34 28 0
    -36 c0 -20 3 -36 8 -36 14 0 107 58 126 79 29 32 38 25 19 -14 -25 -53 -7 -54
    41 -2 46 50 68 60 59 27 -5 -19 -2 -20 38 -14 24 3 50 8 57 10 10 3 11 -2 6
    -18 -5 -18 -3 -20 12 -13 11 4 31 10 47 13 34 5 38 -21 5 -39 l-21 -12 22 -11
    22 -12 -21 -19 c-12 -11 -19 -27 -17 -37 5 -16 -2 -18 -47 -18 -29 0 -77 7
    -107 16 -34 11 -90 17 -154 16 -100 0 -100 0 -205 -50 -106 -51 -203 -82 -258
    -82 -16 0 -49 7 -72 15 -45 16 -97 19 -149 9 -24 -5 -39 -19 -66 -64 -31 -51
    -42 -60 -117 -96 -76 -36 -91 -39 -182 -42 -92 -4 -102 -2 -165 26 -97 45
    -136 50 -167 21 -29 -27 -31 -50 -5 -73 11 -10 22 -29 26 -43 6 -24 7 -25 73
    -18 49 4 71 3 83 -7 18 -14 49 -88 49 -116 0 -9 16 -27 35 -40 42 -26 43 -43
    14 -131 -20 -59 -20 -63 -4 -81 10 -11 26 -22 36 -25 11 -4 19 -13 19 -22 0
    -8 8 -27 19 -41 10 -15 29 -51 41 -79 26 -60 18 -83 -29 -83 -14 0 -41 -4 -59
    -9 -18 -6 -89 -11 -157 -13 l-124 -3 -45 44 c-25 25 -61 74 -80 110 -61 116
    -119 163 -229 187 -43 10 -77 22 -77 29 0 7 -9 20 -19 29 -18 16 -40 18 -208
    14 -201 -3 -205 -4 -277 -62 -45 -36 -81 -31 -91 13 -3 16 -15 37 -26 47 -37
    34 -89 111 -89 133 0 43 27 32 98 -39 64 -63 71 -73 65 -100 -7 -38 4 -38 52
    0 21 17 50 34 64 37 36 10 178 9 223 -1 28 -7 47 -4 75 9 21 10 41 22 44 27 4
    6 -10 42 -30 82 l-37 71 -124 -2 c-69 -2 -170 -5 -225 -8 -55 -4 -154 -7 -220
    -9 -140 -3 -115 8 -331 -136 -23 -16 -52 -31 -65 -35 -61 -19 -77 -71 -36
    -115 13 -14 53 -44 88 -68 55 -35 72 -54 109 -118 55 -96 74 -109 165 -109 62
    0 75 4 119 32 56 37 86 43 80 15 -5 -21 3 -32 39 -55 17 -11 27 -26 27 -41 0
    -33 32 -57 87 -67 26 -5 53 -15 58 -23 6 -7 24 -20 41 -29 17 -9 36 -23 43
    -31 17 -21 40 -20 64 1 13 13 37 18 79 19 69 1 70 2 94 94 20 76 52 116 107
    131 42 11 29 19 160 -92 99 -83 163 -120 229 -131 59 -11 67 -10 94 9 18 13
    51 22 88 25 l58 5 15 -34 c10 -26 11 -38 3 -48 -19 -22 86 -41 146 -26 48 12
    63 6 64 -25 0 -11 72 -55 109 -66 14 -4 39 -1 62 9 37 14 39 18 39 58 0 30 -6
    47 -21 61 -46 42 -60 100 -38 162 8 25 7 35 -7 57 -9 14 -15 27 -13 29 2 2 78
    29 168 60 l165 57 28 -28 28 -28 -25 -53 c-32 -69 -32 -97 0 -118 23 -15 27
    -15 43 -2 31 27 68 30 104 8 36 -22 82 -26 122 -10 21 8 26 16 26 43 0 40 12
    57 68 91 24 15 53 43 64 62 25 43 65 64 133 71 68 7 112 -11 124 -52 19 -63
    20 -82 6 -102 -8 -11 -19 -41 -26 -67 -9 -37 -15 -46 -28 -41 -27 11 -89 18
    -105 12 -24 -10 0 -54 33 -60 74 -15 150 -18 189 -8 41 12 42 11 73 -24 17
    -19 38 -35 46 -35 24 0 161 128 182 169 11 20 24 55 30 78 7 24 23 51 38 63
    l26 21 26 -25 c14 -14 38 -27 52 -31 23 -6 27 -14 37 -69 16 -84 15 -137 -3
    -160 -22 -29 -18 -70 8 -103 45 -55 107 -164 115 -201 l8 -37 89 -13 c71 -10
    95 -18 114 -37 23 -23 24 -27 14 -82 -6 -32 -8 -62 -4 -66 12 -12 232 13 264
    30 22 11 35 12 56 4 14 -6 45 -15 68 -20 39 -8 44 -7 58 16 9 14 38 34 65 45
    29 12 50 28 53 40 5 17 11 19 39 13 44 -10 41 -7 94 -76 41 -53 47 -67 51
    -122 6 -73 13 -80 108 -102 39 -9 77 -19 86 -21 9 -3 23 -19 30 -36 22 -53 60
    -68 176 -68 l101 0 12 -37 c7 -21 16 -42 20 -46 4 -5 31 3 59 17 l51 26 9 -22
    c22 -61 21 -73 -10 -103 -45 -42 -54 -73 -29 -100 26 -28 26 -32 -10 -50 -24
    -13 -29 -20 -24 -39 8 -35 -20 -94 -47 -98 -14 -2 -31 -17 -43 -37 l-20 -34
    32 -27 c40 -33 38 -40 -34 -137 -67 -90 -69 -123 -9 -135 31 -5 29 -29 -12
    -102 -30 -54 -45 -71 -75 -83 -25 -10 -40 -24 -48 -46 -7 -18 -15 -40 -18 -49
    -4 -9 -23 -28 -42 -41 -33 -22 -35 -26 -35 -83 0 -67 19 -101 72 -131 47 -25
    52 -63 27 -175 -5 -23 -3 -28 13 -28 11 0 28 6 38 14 17 12 22 12 43 -5 31
    -24 38 -19 46 30 9 57 29 59 88 6 l50 -45 40 21 c31 16 43 18 54 8 13 -11 44
    -9 91 6 17 6 22 2 25 -17 2 -17 8 -22 23 -19 11 2 53 6 93 8 66 4 77 2 97 -17
    19 -18 29 -20 51 -13 15 5 31 19 35 30 12 32 -29 104 -69 123 -20 9 -32 22
    -32 35 0 18 5 20 54 17 65 -4 76 12 48 69 l-19 38 34 38 c32 35 36 36 65 25
    18 -6 32 -11 32 -9 1 1 9 19 20 39 18 35 18 39 2 63 -13 19 -16 37 -11 64 3
    20 2 49 -4 64 -10 27 -71 72 -107 79 -15 2 -21 15 -26 58 -7 53 -6 56 22 81
    30 25 30 26 10 44 -16 14 -27 16 -57 10 -21 -5 -69 -9 -108 -11 -39 -1 -87 -9
    -107 -18 -21 -9 -47 -16 -60 -16 -21 0 -21 0 5 28 l27 28 -31 63 c-16 36 -46
    86 -65 113 l-36 48 34 0 c53 0 74 10 84 38 10 27 10 27 39 -25 23 -40 36 -53
    53 -53 30 0 66 39 96 105 25 56 73 97 170 145 41 21 52 32 63 66 10 29 21 44
    36 47 12 3 52 18 90 33 74 31 83 48 45 86 l-22 22 22 14 c12 8 22 21 22 28 0
    17 -31 11 -98 -18 -45 -19 -79 -22 -106 -12 -22 8 -23 126 -1 144 19 16 19 33
    -1 71 -15 31 -15 31 25 72 90 93 130 145 159 210 21 46 36 67 49 67 28 1 107
    145 98 179 -4 14 -7 43 -7 64 0 34 -2 37 -29 37 -35 0 -48 24 -26 48 28 31 32
    153 8 276 -12 59 -24 113 -28 120 -4 6 -52 67 -106 135 l-99 124 6 46 c3 25 9
    72 12 103 l6 56 -38 20 c-22 10 -63 27 -93 38 -59 21 -71 39 -71 104 0 26 6
    42 16 48 15 9 15 12 -2 40 -21 36 -95 72 -148 72 -19 0 -36 6 -38 12 -8 22 14
    77 32 83 16 5 31 29 44 68 7 22 -23 84 -57 115 -40 37 -21 82 35 82 41 0 109
    29 143 61 39 37 56 36 118 -2 44 -27 58 -31 104 -27 29 3 56 8 59 11 7 7 -31
    103 -50 126 -6 8 -26 20 -43 28 -38 16 -42 30 -10 38 116 26 132 25 142 -13 3
    -15 18 -39 32 -54 32 -33 116 -47 164 -27 30 12 33 11 63 -23 35 -39 75 -59
    103 -52 12 4 35 -9 65 -35 25 -23 56 -41 68 -41 21 0 22 3 17 57 -3 32 -8 68
    -12 81 -6 17 -3 22 9 22 9 0 19 -7 22 -15 4 -9 19 -15 40 -15 18 0 52 -7 74
    -16 48 -20 68 -14 72 21 2 22 12 30 59 45 31 11 89 37 130 59 41 21 114 51
    163 66 78 23 92 25 114 13 14 -7 44 -13 68 -13 35 0 49 6 79 35 46 44 57 65
    64 115 6 37 10 41 49 53 40 13 42 16 42 52 0 31 -7 44 -39 73 -22 20 -53 43
    -69 51 -17 9 -40 31 -51 50 -18 28 -31 36 -72 45 -27 6 -59 11 -69 11 -17 0
    -20 7 -20 49 0 45 3 53 33 75 19 15 44 26 55 26 20 0 22 5 22 59 0 93 -2 91
    109 91 79 0 102 4 131 21 49 29 60 47 60 95 0 37 5 47 42 78 25 22 52 36 69
    36 35 0 85 55 124 138 25 54 32 62 50 57 65 -20 96 -27 102 -21 15 15 4 68
    -21 104 -64 93 -55 202 24 287 26 27 29 38 32 120 l3 90 -47 25 c-41 22 -53
    37 -97 120 -27 52 -56 96 -65 98 -8 2 -35 -15 -60 -38 l-45 -42 -93 31 c-51
    17 -129 35 -173 40 -77 8 -107 18 -185 62 -19 10 -45 19 -56 19 -17 0 -24 9
    -33 44 -6 25 -11 60 -11 79 0 18 -7 55 -16 81 -18 54 -14 64 20 48 29 -13 36
    -7 36 29 0 26 3 29 50 35 28 4 50 9 49 13 0 3 -19 20 -42 36 -59 43 -67 84
    -37 181 13 41 34 83 46 95 22 21 24 21 89 4 51 -13 74 -14 97 -6 39 14 105 14
    113 1 3 -5 18 -10 32 -10 14 0 31 -6 37 -14 6 -8 29 -17 51 -21 33 -5 46 -2
    74 19 33 24 35 25 70 9 20 -8 43 -17 52 -20 20 -6 64 49 73 92 4 17 11 146 16
    288 l9 258 29 52 c20 36 31 73 36 120 7 70 37 132 123 250 26 35 40 70 54 134
    10 48 19 97 19 109 0 12 7 27 15 34 27 22 28 77 5 203 -13 67 -33 164 -46 215
    l-22 93 24 55 c14 32 29 93 35 142 13 110 13 121 0 155 -9 24 -16 27 -54 27
    -62 0 -135 25 -189 65 l-47 35 -3 67 -3 68 -82 3 c-76 3 -89 6 -155 44 -40 23
    -90 55 -111 72 -63 51 -110 165 -124 298 -6 60 -9 66 -68 126 -54 57 -62 62
    -74 48z"
        />
        <path
          d="M5942 10050 c-33 -27 -41 -93 -16 -121 10 -12 32 -26 49 -33 42 -18
    123 4 148 39 21 29 38 30 149 13 73 -11 82 -10 165 15 48 15 122 35 163 45 56
    14 76 23 78 36 4 18 -3 18 -109 11 -62 -4 -124 -12 -138 -17 -32 -13 -351 -26
    -358 -15 -5 9 -89 47 -101 47 -4 -1 -18 -9 -30 -20z"
        />
        <path
          d="M5740 10001 c-8 -5 -127 -48 -265 -95 -228 -79 -259 -87 -350 -93
    -123 -7 -141 -14 -150 -51 -3 -17 -22 -44 -41 -61 -37 -34 -40 -47 -22 -86 9
    -21 18 -25 54 -25 37 0 46 4 65 33 35 51 48 57 122 57 l70 0 86 64 c84 62 87
    63 163 69 86 6 108 15 160 65 27 26 42 33 59 29 47 -12 125 59 104 93 -8 12
    -35 12 -55 1z"
        />
        <path
          d="M4689 9440 c-50 -15 -239 -121 -254 -143 -8 -12 -15 -30 -15 -40 0
    -12 -20 -27 -65 -48 -36 -17 -67 -35 -71 -40 -3 -5 -16 -9 -30 -9 -22 0 -25
    -4 -22 -27 3 -27 6 -28 60 -31 l57 -3 68 62 c80 74 85 81 81 116 -3 24 2 28
    53 43 31 10 72 29 90 43 19 13 49 28 67 32 32 7 62 28 62 46 0 11 -44 11 -81
    -1z"
        />
        <path
          d="M4178 8962 c-19 -21 -67 -91 -108 -157 -40 -66 -94 -151 -119 -190
    -41 -64 -46 -77 -52 -150 -4 -44 -9 -93 -13 -110 -10 -46 22 -77 84 -83 45 -4
    50 -3 50 16 0 12 -4 24 -10 27 -5 3 -10 15 -10 25 0 18 2 18 35 4 44 -18 41
    -19 55 15 7 17 30 39 58 54 26 14 66 45 89 69 38 39 45 54 74 168 36 139 35
    173 -6 234 -13 19 -23 47 -22 62 2 22 -4 29 -34 39 -36 13 -37 12 -71 -23z"
        />
        <path
          d="M5880 6834 c-49 -23 -96 -56 -135 -93 -53 -51 -66 -59 -108 -64 -66
    -7 -87 -28 -87 -90 0 -76 -22 -98 -169 -169 -103 -51 -139 -74 -201 -133 -41
    -39 -126 -103 -187 -143 -62 -40 -113 -77 -113 -82 0 -6 -23 -21 -50 -35 -28
    -14 -53 -32 -56 -40 -9 -24 41 -120 75 -144 32 -23 32 -23 96 -6 63 17 66 17
    118 -2 29 -12 88 -44 131 -72 43 -28 85 -51 93 -51 8 0 45 -32 83 -71 60 -63
    71 -71 106 -72 53 -2 170 32 217 62 67 43 102 141 83 228 -12 53 5 70 84 83
    56 9 70 15 70 30 0 9 17 36 38 59 29 32 61 51 145 86 60 25 117 45 126 45 9 0
    33 21 52 46 19 25 46 63 61 83 15 20 41 74 59 121 28 76 30 94 27 172 -3 66
    -9 92 -22 107 -23 26 -160 92 -211 102 -77 15 -163 44 -182 62 -29 25 -60 21
    -143 -19z"
        />
        <path
          d="M2094 4021 c-34 -16 -76 -32 -95 -36 -85 -19 -117 -88 -64 -140 26
    -27 40 -31 50 -15 3 6 24 10 46 10 36 0 39 -2 29 -19 -15 -29 -13 -31 25 -31
    38 0 43 10 20 50 -12 22 -11 27 2 43 22 24 74 21 114 -6 33 -22 34 -26 37 -92
    3 -82 23 -132 79 -194 38 -43 40 -44 117 -50 75 -6 80 -8 111 -44 24 -28 40
    -37 64 -37 17 0 74 -21 129 -48 94 -47 99 -49 166 -44 66 4 74 7 133 55 49 40
    63 56 63 78 0 29 11 25 -135 45 -33 5 -58 18 -99 53 -31 25 -87 69 -126 96
    -50 36 -72 59 -78 80 -8 28 -13 30 -63 36 -41 5 -68 15 -111 46 -32 22 -70 46
    -86 52 -15 7 -36 25 -45 41 -12 20 -28 30 -55 35 -27 4 -42 14 -54 35 -15 27
    -22 30 -65 30 -32 0 -68 -10 -109 -29z"
        />
        <path
          d="M1925 3679 c-22 -4 -96 -8 -165 -9 -160 -1 -215 -18 -247 -76 -37
    -65 -29 -145 18 -169 39 -21 56 -19 123 15 68 35 144 41 167 14 9 -11 23 -14
    47 -11 29 5 35 2 52 -26 11 -18 20 -44 20 -58 0 -35 58 -109 87 -109 12 0 25
    5 28 10 10 16 21 12 68 -24 41 -31 47 -33 93 -26 106 17 204 61 204 90 0 11 9
    28 20 37 25 22 26 45 2 66 -22 20 -125 57 -158 57 -27 0 -42 25 -70 113 -26
    83 -35 89 -118 74 l-68 -11 -22 27 c-12 15 -26 26 -31 26 -6 -1 -28 -5 -50
    -10z"
        />
        <path
          d="M2336 3165 c-14 -15 -34 -25 -50 -25 -39 0 -96 -27 -96 -45 0 -9 25
    -42 55 -73 31 -31 65 -72 78 -89 12 -18 29 -36 37 -39 27 -11 164 -34 208 -34
    38 0 45 -4 63 -35 29 -48 50 -34 84 55 29 76 27 96 -11 138 -13 15 -36 52 -52
    82 l-28 55 -75 0 c-54 0 -81 5 -99 18 -36 25 -86 22 -114 -8z"
        />
        <path
          d="M1015 2430 c-34 -16 -78 -29 -101 -29 -55 -1 -103 -18 -136 -49 -34
    -31 -34 -39 -7 -103 l21 -49 -27 -36 c-27 -36 -28 -39 -15 -83 7 -25 16 -64
    19 -86 5 -35 16 -47 84 -102 62 -49 84 -61 105 -58 15 2 49 7 75 10 55 7 60
    15 71 106 l7 57 58 6 c49 6 61 11 80 36 12 17 27 30 34 30 7 0 77 -36 156 -80
    133 -74 149 -80 201 -80 l57 0 12 -57 c6 -31 11 -66 11 -77 0 -12 11 -28 23
    -37 30 -22 183 -44 277 -40 70 2 76 4 115 40 22 20 62 52 88 70 41 28 53 32
    86 26 63 -10 121 27 273 177 171 168 195 200 179 234 -6 14 -11 34 -11 45 0
    12 -4 17 -11 14 -6 -4 -7 -19 -4 -36 4 -16 5 -29 2 -29 -2 0 -21 14 -42 30
    -26 21 -42 27 -49 20 -5 -5 -27 -10 -48 -10 -30 0 -38 -4 -38 -18 0 -11 10
    -24 23 -30 20 -10 21 -11 2 -11 -11 0 -29 6 -39 14 -11 8 -30 15 -43 15 -20 0
    -21 -3 -13 -26 17 -43 12 -43 -34 2 -24 25 -53 44 -65 44 -44 0 -89 28 -115
    72 -26 45 -64 74 -106 80 -20 2 -26 -2 -28 -22 -2 -14 -7 -29 -12 -35 -4 -5
    -10 -27 -14 -47 -7 -38 -7 -38 -56 -38 -45 0 -53 -4 -86 -40 -51 -55 -145 -79
    -174 -45 -7 8 -23 15 -36 15 -14 0 -33 5 -44 12 -15 10 -26 9 -55 -5 -32 -15
    -39 -16 -68 -2 -18 8 -60 37 -94 64 -54 41 -71 49 -122 55 -48 6 -68 14 -101
    41 -38 32 -153 76 -191 74 -8 0 -41 -13 -74 -29z"
        />
      </g>
    </svg>
  );
};
