import { StepFormDataType, StepFormStepType } from "../../StepFormTypes";
import { stepFormAuSubforms } from "./formData-ai-SubForms";
import { getStepFormOptionButton } from "./formDataAiUtils";

export const stepFormDataAI: StepFormDataType = {
  firstStep: "ai-1",
  steps: [
    {
      id: "ai-1",
      title: "Hello, I'm the boring assistant. How can I help you ?",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            getStepFormOptionButton(
              "Book my free AI discovery session",
              "bookCall-1"
            ),
            getStepFormOptionButton(
              "I need experts for my AI project.",
              "findExpert-1"
            ),
            getStepFormOptionButton(
              "I’m an AI agency or expert looking for projects.",
              "talent-1"
            ),
            getStepFormOptionButton(
              "How can AI help my business ? / Other questions",
              "questions-1"
            ),
          ],
        },
      },
    },
    ...stepFormAuSubforms,
  ],
};
