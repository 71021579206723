import { useTranslate } from "../../hooks/useTranslation";
import { servicesData } from "./servicesData";

export type ServiceType = {
  headline: string;
  text: string;
  listOfItems?: { text: string }[];
};

export type ServicesPropsType = {
  headline?: string;
  services?: ServiceType[];
  className?: string;
};

export const Services = ({
  services = servicesData,
  headline,
  className,
}: ServicesPropsType) => {
  const translate = useTranslate();

  const isEven = (i: number) => i % 2;

  return (
    <div className={`${className}`}>
      <h2 className="heading-2 w-full text-center">{headline}</h2>
      <div className="mt-20">
        {services.map((service, index) => {
          return (
            <div
              className={`py-10 border-t border-grey flex-col-reverse flex ${
                isEven(index) ? "md:flex-row " : "md:flex-row-reverse"
              }   `}
            >
              <div
                className={`w-full flex flex-wrap px-5 items-center flex-col gap-5 ${
                  isEven(index) ? "md:items-end " : "md:items-start"
                } `}
              >
                {service.listOfItems?.map((item) => (
                  <div className="body-2 nowrap text-center md:text-left">{translate(item.text)}</div>
                ))}
              </div>
              <div
                className={`flex flex-col gap-5 w-full px-5 items-center border-grey justify-center ${
                  isEven(index) ? "border-l md:items-start" : "md:items-end md:border-r "
                }`}
              >
                <h3 className="heading-3">{translate(service.headline)}</h3>
                <p className="body-1  mb-5 md:mb-0 italic md:text-left text-center text-darkGrey">
                  {translate(service.text)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
