import { useTranslate } from "../../hooks/useTranslation";
import { DataFacts } from "../../modules/DataFacts/DataFacts";
import { TextCards } from "../../modules/TextCards/TextCards";
import { Hero } from "../../modules/Hero/Hero";
import { Services } from "../../modules/Services/Services";
import { PageWrapper } from "../PageWrapper";
import { CallToAction } from "../../modules/CallToAction/CallToAction";
import { Carousel } from "../../Components/Carousel/Carousel";
import { FAQ } from "../../modules/FAQ/FAQ";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";

export const AIPage = () => {
  const translate = useTranslate();
  const moduleSpacingClassName = "lg:mt-60 md:mt-40 mt-20";
  const moduleWidthClassNAme = "max-w-[2520px] mx-auto";
  const moduleClassName = `${moduleSpacingClassName} ${moduleWidthClassNAme}`;
  return (
    <PageWrapper>
      <Hero
        headline={translate("ai.hero.headline")}
        stepFormData={stepFormDataAI}
        className={moduleWidthClassNAme}
        overline={translate("ai.hero.subheadline")}
      />
      <DataFacts className={moduleClassName} headline={translate("ai.dataFacts.headline")} />
      <Services
        className={moduleClassName}
        headline={translate("ai.services.headline")}
      />
      <TextCards
        text={translate("ai.experience.text")}
        className={moduleClassName}
        headline={translate("ai.experience.headline")}
      />
      <CallToAction
        className={moduleClassName}
        headline={translate("ai.callToAction.headline")}
      />
      <Carousel className={`full-width-section ${moduleSpacingClassName}`} />
      <FAQ className={moduleClassName} />
    </PageWrapper>
  );
};
