export const getStepFormOptionButton = (label: string, nextStepId: string) => {
  const getButtonIdFromLabel = (label: string) => {
    return label
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/\//g, "-")
      .replace(/[^a-z0-9\-]/g, "");
  };
  return {
    id: getButtonIdFromLabel(label),
    label: label,
    nextStepId: nextStepId,
  };
};

export const getOptionButtonFromArray = (
  array: string[],
  nextStepId: string
) => {
  return array.map((industry) => getStepFormOptionButton(industry, nextStepId));
};
