import { useTranslate } from "../../hooks/useTranslation";
import { StepForm } from "../../StepForm/StepForm";
import { StepFormDataType } from "../../StepForm/StepFormTypes";

type HeroPropsTypes = {
  headline: string;
  overline?: string;
  className?: string;
  stepFormData?: StepFormDataType;
};

export const Hero = ({
  headline,
  overline,
  className,
  stepFormData,
}: HeroPropsTypes) => {
  const translate = useTranslate();

  return (
    <div
      className={`pt-60 pb-0 md:pt-80 lg:pt-96 gap-10 relative flex flex-column flex-wrap lg:flex-nowrap lg:flex-row justify-between ${className}`}
    >
      <div className=" w-full lg:w-4/5">
        {overline && <h3 className="heading-3">{overline}</h3>}
        <h1 className="heading-1">{headline}</h1>
      </div>
      {stepFormData && (
        <StepForm
          stepFormData={stepFormData}
          className="w-[600px] h-[500px] ml-auto"
        />
      )}
    </div>
  );
};
