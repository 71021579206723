import { StepFormStepType } from "../../StepFormTypes";
import {
  getOptionButtonFromArray,
  getStepFormOptionButton,
} from "./formDataAiUtils";

const talentExperience = [
  "No experience",
  "Under 1 year",
  "1-3 Years",
  "3-5 Years",
  "Over 5 years",
];

const aiSubfields = [
  "AI Strategy / Consultancy",
  "AI Solutions",
  "Machine learning",
  "Neural Networks",
  "Computer vision",
  "Deep learning",
  "Data Engineering / Science",
  "Other",
  "Multiple areas",
];

const availability = [
  "Immideately",
  "Within 1 month",
  "1-3 months",
  "3/6 months",
  "More than 6 months",
];

const teamSize = ["2-5", "5-10", "10-15", "Over 15"];

export const stepFormDataTalent: StepFormStepType[] = [
  {
    id: "talent-1",
    title:
      "We are always in need of experts and teams for our projects.<br>Do you work on your own or have a team ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton(
            "I am a professional",
            "talent-professional-1"
          ),
          getStepFormOptionButton("We are a team", "talent-team-1"),
        ],
      },
    },
  },
  {
    id: "talent-team-1",
    title: "How much experience do you have in the AI field ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          ...getOptionButtonFromArray(talentExperience, "talent-team-2"),
        ],
      },
    },
  },
  {
    id: "talent-team-2",
    title: "How big is your team ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(teamSize, "talent-2")],
      },
    },
  },
  {
    id: "talent-professional-1",
    title: "How much experience do you have in the AI field ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(talentExperience, "talent-2")],
      },
    },
  },
  {
    id: "talent-2",
    title: "What are you mostly focused on",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(aiSubfields, "talent-3")],
      },
    },
  },
  {
    id: "talent-3",
    title: "When would you be available to pick up projects ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(availability, "talent-4")],
      },
    },
  },
  {
    id: "talent-4",
    title:
      "Looks good! Someone within the team will check the information and get in touch to discuss the details, How can we contact you ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton("Preview version only", "none"),
        ],
      },
    },
  },
];
