import { CTACard } from "../../Components/CTACard/CTACard";
import { useTranslate } from "../../hooks/useTranslation";
import { StepForm } from "../../StepForm/StepForm";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";

type CallToActionPropsType = {
  className?: string;
  headline?: string;
};

export const CallToAction = ({
  className,
  headline,
}: CallToActionPropsType) => {
  const translate = useTranslate();
  return (
    <div className={` ${className}`}>
      <h2 className="heading-2 text-center md:text-right md:ml-auto w-full lg:w-1/2">{headline}</h2>
      <div className="rounded-2xl mt-10 lg:mt-20 flex gap-5 lg:gap-10 flex-col lg:flex-row">
        <StepForm
          stepFormData={stepFormDataAI}
          overline={translate("ai.callToAction.ctaCards.a.overline")}
          className="h-[300px] bg-dark w-full"
          firstStep="bookCall-1"
        />
        <StepForm
          stepFormData={stepFormDataAI}
          overline={translate("ai.callToAction.ctaCards.b.overline")}
          className="h-[300px] bg-dark w-full"
          firstStep="talent-1"
        />
      </div>
    </div>
  );
};
