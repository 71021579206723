export const SafeTextRenderer = ({ text, className }: { text: string, className?: string; }) => {
  // Split the string at every occurrence of '<br>'
  const parts = text.split(/<br\s*\/?>/gi);

  return (
    <>
      {parts.map((part, index) => (
        <span  className={className} key={index}>
          {part}
          {index < parts.length - 1 && <br />}{" "}
          {/* Only add <br> between parts */}
        </span>
      ))}
    </>
  );
};
