const homeServicesTransaltions = {
  headline: {
    en: "Ideal Solutions for your needs",
  },
  items: {
    a: {
      headline: {
        en: "AI strategy consulting",
      },
      text: {
        en: "Discover and transform ideas into a competitive AI blueprint.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Find hidden opportunities in your operations",
          },
        },
        b: {
          text: {
            en: "Identify high-impact areas for AI integration",
          },
        },
        c: {
          text: {
            en: "Validate Winning Use Cases",
          },
        },
        d: {
          text: {
            en: "Accelerate with Proof of Concept",
          },
        },
      },
    },
    b: {
      headline: {
        en: "AI solutions",
      },
      text: {
        en: "Elevate your business with strategic AI solutions.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Streamline workflows and automate processes",
          },
        },
        b: {
          text: {
            en: "Reduce costs and minimize errors with intelligent automation",
          },
        },
        c: {
          text: {
            en: "Uncover new revenue streams through AI products and services",
          },
        },
        d: {
          text: {
            en: "Boost sales with AI lead scoring and conversion optimization",
          },
        },
      },
    },
    c: {
      headline: {
        en: "AI Talent & Agency Network",
      },
      text: {
        en: "Access AI Talent & Agencies through our curated network.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Pre-vetted experts who align with your project scope",
          },
        },
        b: {
          text: {
            en: "Dedicated AI teams to suit your evolving needs",
          },
        },
        c: {
          text: {
            en: "Individual AI experts for specialized expertise",
          },
        },
        d: {
          text: {
            en: "End-to-end management of all engaged talent and teams",
          },
        },
      },
    },
  },
};

const homeDataFactsTranslations = {
  headline: {
    en: "We are in the race to embrace AI, but pinpointing your ideal application can be daunting.",
  },
  cards: {
    a: {
      en: "of companies believe AI will allow them to obtain a competitive advantage.",
    },
    b: {
      en: "of executives believe AI is a strategic priority for their businesses today.",
    },
    c: {
      en: "of Companies will Adopt Intelligent Automation by 2025.",
    },
    d: {
      en: "new jobs AI is estimated to create by 2030.",
    },
  },
};

const homeExperienceTranslations = {
  headline: {
    en: "AI in Action: Real-World Business Transformation",
  },
  text: {
    en: "It is impossible to map all use cases, but this are some examples",
  },
  cards: {
    a: {
      headline: {
        en: "Legal Document Analysis",
      },
      text: {
        en: "Law firms apply AI to review large volumes of legal documents, speeding up contract analysis and reducing the risk of missing key details.",
      },
    },
    b: {
      headline: {
        en: "Personalized Learning in Education",
      },
      text: {
        en: "Edtech companies implement AI to create personalized learning paths for students, adapting content and difficulty based on individual progress and needs.",
      },
    },
    c: {
      headline: {
        en: "Smart Recruitment",
      },
      text: {
        en: "Human Resources departments use AI to screen resumes and shortlist candidates based on predefined criteria, speeding up the hiring process and improving candidate matching.",
      },
    },
    d: {
      headline: {
        en: "Technical Support for Industrial Machinery",
      },
      text: {
        en: "Manufacturing companies use AI to access technical manuals for their machines and assists technicians by quickly providing troubleshooting steps, repair guides, and operational instructions, reducing downtime and improving efficiency.",
      },
    },
    e: {
      headline: {
        en: "Financial Reports Summaries",
      },
      text: {
        en: "Financial firms use AI to analyze detailed company financial reports, extract key insights and generate concise summaries, saving time and helping decision-makers focus on critical data for investment planning.",
      },
    },
    f: {
      headline: {
        en: "Store Assistant Boosts User Conversion Rates",
      },
      text: {
        en: "Retail companies implement AI assistants on their website to answer customer questions instantly. By providing quick, accurate responses on products and services, the AI increased customer engagement, and boosted conversion rates.",
      },
    },
  },
};

const homeCallToActionTranslations = {
  headline: {
    en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
  },
  ctaCards: {
    a: {
      buttonText: { en: "Book call" },
      headline: { en: "Headline" },
      overline: { en: "For companies" },
      text: {
        en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      },
    },
    b: {
      buttonText: { en: "Book free call" },
      headline: { en: "Headline" },
      overline: { en: "For developers" },
      text: {
        en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      },
    },
  },
};

const qaTranslations = {
  headline: {
    en: "The Only Wrong Question Is the One Left Unasked",
  },
  text: {
    en: "This is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... ",
  },
  items: {
    a: {
      question: {
        en: "How can AI enhance my business operations?",
      },
      answer: {
        en: "AI can revolutionize virtually every aspect of your business, from streamlining internal processes to enhancing customer experiences and creating new revenue streams. Its applications are vast and varied across industries. We invite you to explore our comprehensive list of industry-specific use cases to see how AI can transform your particular business area.",
      },
    },
    b: {
      question: {
        en: "What process do you use to identify the most impactful AI applications for my specific business needs? ",
      },
      answer: {
        en: "We begin by pairing you with an AI expert specialized in your industry. This expert, along with a dedicated team, will conduct an in-depth analysis of your company's processes and specific requirements. We then facilitate a brainstorming session with a group of experts to generate ideas tailored to your needs. Finally, we develop a customized AI blueprint and refine it through iterative discussions with you to ensure it aligns perfectly with your vision and goals.",
      },
    },
    c: {
      question: {
        en: "What type of AI services and solutions do you provide?",
      },
      answer: {
        en: "We offer a comprehensive suite of AI services, including:Custom strategy and blueprint development with industry-specific experts Full-scale AI solution development Matching you with specialized AI teams or experts experienced in your industry Experienced project management for all AI initiatives AI talent acquisition services to help you build your in-house team",
      },
    },
    d: {
      question: {
        en: "Do you cater to businesses of all sizes?",
      },
      answer: {
        en: "Absolutely. Our services are designed to accommodate businesses of all scales, from small startups to large enterprises, as well as individuals looking to leverage AI in their projects or careers.",
      },
    },
    e: {
      question: {
        en: "If I have a well-defined AI project in mind, do you offer development-only services?",
      },
      answer: {
        en: " Yes, we offer development-only services for well-defined projects. Our team can develop your solution directly, or we can connect you with specialized teams that have extensive experience in your specific industry or use case.",
      },
    },
    f: {
      question: {
        en: "How can Millerio help us fill an open position for an AI expert in our company?",
      },
      answer: {
        en: "We streamline your hiring process by pre-screening and testing AI experts who match your specific requirements. Our thorough vetting ensures that only the most qualified candidates, tailored to your unique needs, are presented to you, saving you time and resources in the initial stages of recruitment.",
      },
    },
    g: {
      question: {
        en: "Can you source AI specialists or teams with specific expertise from particular geographic regions?",
      },
      answer: {
        en: "Our extensive network spans all continents, with specialists in various AI technologies and industries. We can certainly locate and connect you with verified experts or teams from your desired region who possess the specific expertise you require.",
      },
    },
    h: {
      question: {
        en: "Which industries do you serve, and do you have any sector-specific expertise? ",
      },
      answer: {
        en: "We serve all industries and sectors. Our vast network allows us to connect you with experts who have direct, relevant experience in your specific sector, ensuring you receive specialized knowledge and insights.",
      },
    },
    i: {
      question: {
        en: "What AI technologies does your team specialize in? ",
      },
      answer: {
        en: "Our network encompasses experts in all AI technologies. This allows us to cover any possible use case or requirement, ensuring we can match you with the right expertise for your specific needs.",
      },
    },
    j: {
      question: {
        en: "I'm an AI professional or represent an AI agency. How can I collaborate with your company on projects? ",
      },
      answer: {
        en: "We're always eager to expand our network with skilled AI professionals and agencies. This allows us to source a wider range of projects and opportunities. To join our network and explore collaboration possibilities, please use [this link] to register. We look forward to potentially working together!",
      },
    },
  },
};

export const aiTranslations = {
  hero: {
    headline: {
      en: "Linking AI Experts to Innovations",
    },
    subheadline: {
      en: "From idea to implementation.",
    },
    ctaCard: {
      buttonText: { en: "Book free call" },
      headline: { en: "Headline" },
      text: {
        en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      },
    },
  },
  dataFacts: homeDataFactsTranslations,
  services: homeServicesTransaltions,
  experience: homeExperienceTranslations,
  callToAction: homeCallToActionTranslations,
  qa: qaTranslations,
};
