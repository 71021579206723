export const themeColors = {
  transparent: "transparent",
  current: "currentColor",
  white: "#ffffff",
  dark: "#0C0C0C",
  background: "#17191e",
  accent: "#ff407f",
  grey: "#f0f0f0",
  darkGrey: "#444444",
  stroke: "#21272a",
  blue: "#50A5FF",
};
