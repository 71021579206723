export const SpainSvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1024.000000 1024.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke={stroke}
      >
        <path
          d="M1157 8887 c-10 -23 -25 -38 -46 -45 -33 -12 -38 -8 -50 33 l-6 24
        -44 -30 c-25 -16 -52 -28 -60 -27 -23 4 -41 -10 -41 -32 0 -12 -16 -30 -40
        -45 -22 -14 -47 -36 -55 -49 -11 -16 -30 -27 -55 -31 -27 -5 -39 -12 -38 -23
        0 -9 -3 -34 -7 -54 -6 -30 -5 -38 7 -38 9 0 21 -7 28 -15 7 -8 23 -15 36 -15
        21 0 22 -3 17 -37 -3 -21 -8 -40 -10 -43 -2 -2 -21 15 -41 37 -20 23 -46 43
        -58 45 -12 2 -38 -12 -65 -36 -56 -49 -116 -76 -182 -83 -44 -4 -55 -2 -71 16
        -29 32 -71 27 -106 -11 -18 -21 -27 -41 -24 -53 3 -16 -6 -25 -44 -43 -26 -12
        -57 -22 -69 -22 -12 0 -35 -11 -50 -25 -26 -23 -27 -26 -11 -39 19 -14 14 -26
        -12 -26 -9 0 -26 -10 -38 -23 -19 -20 -22 -35 -22 -108 0 -101 11 -126 34 -80
        17 32 23 35 50 25 21 -8 44 -110 28 -126 -18 -18 -15 -26 15 -41 33 -15 67
        -12 83 9 7 8 21 14 32 14 18 0 17 -3 -9 -27 -16 -16 -35 -44 -42 -63 -7 -19
        -20 -52 -28 -72 -13 -31 -14 -42 -3 -68 18 -43 42 -38 82 19 28 40 37 47 51
        40 23 -14 37 -3 37 28 0 25 0 26 20 8 11 -10 20 -23 20 -29 0 -11 -36 -109
        -51 -139 -5 -9 -6 -35 -3 -57 6 -39 8 -40 47 -40 l40 0 -27 -25 c-14 -13 -26
        -33 -26 -45 0 -11 -18 -38 -40 -60 -44 -43 -52 -60 -18 -36 12 9 32 16 44 16
        12 0 43 9 69 20 54 23 65 25 65 8 0 -7 -23 -28 -50 -47 -28 -19 -50 -38 -50
        -43 0 -5 -11 -14 -25 -20 -20 -9 -23 -14 -13 -25 9 -11 9 -17 -3 -24 -8 -5
        -20 -9 -26 -9 -10 0 -13 -27 -13 -100 l0 -101 28 15 c15 8 36 23 46 35 11 12
        30 21 42 21 14 0 36 14 54 34 19 20 44 36 63 39 18 3 52 10 77 16 25 6 66 15
        93 20 26 4 56 16 66 25 18 17 20 17 40 -3 12 -12 26 -21 31 -21 12 0 50 -37
        50 -49 0 -5 -13 -14 -29 -21 -27 -11 -81 -77 -81 -99 0 -5 9 -13 20 -16 15 -5
        20 -15 20 -40 0 -69 74 -62 153 13 l38 37 20 -27 20 -28 40 21 c29 15 49 18
        77 13 50 -9 92 -31 92 -49 0 -23 18 -18 46 12 l26 28 28 -35 c23 -28 31 -33
        41 -22 7 6 34 12 61 12 38 0 54 6 82 30 31 27 34 34 29 70 l-6 40 39 0 c24 0
        45 -7 59 -20 21 -20 23 -20 53 -3 26 15 35 16 52 6 27 -18 60 -16 81 3 16 14
        20 14 48 -7 26 -19 38 -21 90 -16 l61 7 0 -34 c0 -20 8 -40 20 -51 19 -18 19
        -18 1 -49 -26 -43 -35 -103 -20 -137 l12 -29 91 6 91 6 28 -46 29 -46 -55 -98
        c-38 -67 -76 -117 -120 -160 -60 -57 -69 -62 -110 -62 -42 0 -48 -3 -86 -51
        -27 -34 -41 -62 -41 -81 0 -17 -9 -43 -21 -59 -17 -24 -28 -29 -61 -29 -39 0
        -40 -1 -35 -29 4 -16 21 -53 38 -81 29 -47 32 -59 30 -124 -1 -40 3 -85 10
        -99 10 -21 10 -36 0 -68 -10 -32 -10 -44 0 -55 9 -11 9 -24 0 -53 -14 -48 -14
        -76 0 -99 8 -13 9 -36 3 -76 l-9 -58 -55 -22 c-44 -18 -60 -30 -78 -64 -29
        -52 -23 -78 21 -86 48 -9 74 -75 69 -178 -3 -73 -5 -77 -32 -88 -20 -8 -31
        -21 -34 -41 -3 -16 -8 -42 -11 -58 -5 -22 -14 -30 -43 -39 -80 -24 -178 -35
        -228 -25 -27 6 -72 8 -101 6 l-51 -5 10 -31 c6 -19 42 -63 90 -108 l81 -76
        -18 -31 c-19 -31 -18 -32 5 -82 13 -28 30 -56 37 -62 7 -6 13 -26 13 -45 0
        -27 9 -44 48 -83 45 -45 49 -47 72 -35 23 12 28 11 53 -13 37 -35 36 -83 -2
        -141 -23 -35 -28 -49 -20 -68 8 -22 1 -29 -80 -89 -95 -69 -104 -83 -100 -160
        1 -22 -8 -58 -20 -84 -26 -56 -26 -80 -2 -87 12 -3 36 -37 62 -89 24 -47 52
        -92 62 -102 11 -9 23 -27 28 -40 10 -26 31 -28 88 -7 l41 14 -15 -23 c-9 -13
        -20 -52 -26 -87 -6 -35 -15 -67 -21 -70 -6 -4 -24 -2 -40 3 -25 9 -33 7 -52
        -11 -13 -12 -35 -21 -53 -21 -29 0 -31 -2 -43 -62 -10 -54 -19 -69 -70 -122
        -51 -54 -59 -67 -65 -115 -4 -30 -18 -75 -31 -99 -31 -57 -30 -63 9 -87 l32
        -20 3 -133 3 -132 45 0 c25 0 59 7 76 16 26 14 49 15 132 9 100 -7 140 -19
        165 -49 8 -9 26 -16 41 -16 15 0 44 -13 66 -31 22 -17 62 -42 89 -56 86 -44
        114 -72 151 -149 36 -76 41 -103 23 -121 -9 -9 -9 -23 1 -57 11 -41 16 -46 62
        -63 27 -9 53 -22 57 -28 12 -15 31 -100 31 -139 0 -19 4 -37 9 -41 5 -3 17
        -27 26 -54 10 -32 24 -52 40 -59 16 -8 25 -20 25 -35 0 -37 33 -61 73 -53 46
        9 63 0 88 -43 16 -30 32 -41 83 -59 34 -12 91 -25 127 -28 108 -11 146 21 236
        193 45 87 68 119 100 142 22 16 44 34 47 40 4 5 28 13 54 17 26 3 56 12 67 18
        11 6 63 11 115 11 l95 0 3 27 c3 23 11 29 55 43 39 13 64 30 98 68 l45 50 125
        21 c158 25 420 27 488 2 65 -24 118 -21 163 10 32 22 44 25 85 20 26 -3 94 -9
        151 -12 75 -5 105 -10 109 -20 4 -10 26 -14 84 -14 96 0 125 12 133 54 9 48
        65 76 149 76 64 0 65 0 113 -50 49 -50 87 -63 98 -35 3 8 11 15 19 15 18 0 64
        56 91 111 12 24 32 48 44 54 13 6 22 19 22 33 0 12 8 39 18 60 27 52 45 128
        39 153 -4 16 0 23 14 26 11 3 38 32 59 63 43 64 117 120 156 120 19 0 28 7 35
        30 7 17 18 30 28 30 9 0 28 13 42 29 19 22 38 30 78 36 40 5 56 4 61 -6 7 -10
        17 -10 57 0 26 7 93 16 148 20 105 8 138 19 155 51 9 16 7 21 -7 26 -48 15
        -65 26 -82 55 l-19 31 33 34 c18 18 43 59 55 90 11 31 29 69 39 83 11 14 24
        46 31 71 30 117 40 139 68 142 18 2 28 10 30 23 1 11 7 44 14 73 9 43 15 52
        33 52 16 0 29 13 46 46 19 37 36 52 84 76 33 17 68 37 78 46 10 9 33 21 50 27
        21 7 38 23 51 50 18 35 27 41 63 46 26 3 54 16 77 37 20 18 43 32 51 32 21 0
        29 19 17 43 -6 12 -13 34 -16 49 -5 24 -15 30 -79 48 -108 32 -121 38 -165 83
        -43 44 -70 117 -91 243 -5 27 -23 77 -41 110 -30 55 -32 64 -27 135 5 72 10
        86 78 215 40 77 90 159 111 183 22 25 54 77 72 115 23 48 47 82 75 104 23 18
        50 50 61 70 27 49 88 125 123 152 15 12 27 30 27 41 0 26 25 75 61 119 16 19
        29 47 29 62 0 36 74 94 133 103 52 9 85 26 116 59 26 28 23 56 -7 56 -10 0
        -27 9 -37 20 -10 11 -24 20 -31 20 -51 0 -11 62 114 176 110 100 156 122 193
        88 18 -16 20 -16 40 10 29 36 41 42 197 86 75 21 164 49 197 61 33 11 94 24
        135 29 117 11 165 39 218 128 30 49 58 82 90 104 26 17 63 44 82 59 35 28 109
        59 186 79 23 5 61 25 85 43 24 18 59 39 77 46 18 8 37 22 42 31 5 10 27 27 47
        37 21 11 44 32 51 47 8 15 29 33 50 41 45 18 69 75 51 122 -6 15 -7 48 -4 74
        6 44 5 47 -29 73 -32 24 -36 32 -36 72 0 48 1 48 65 45 38 -2 42 0 62 38 l22
        40 -32 19 c-18 11 -42 23 -54 26 -13 4 -24 18 -28 37 -5 20 -12 29 -21 25 -8
        -3 -21 7 -31 25 -26 44 -53 46 -130 10 -38 -18 -92 -34 -125 -38 -56 -6 -58
        -7 -64 -38 -5 -30 -10 -33 -47 -38 -30 -3 -48 1 -62 13 -20 17 -125 73 -157
        84 -11 3 -21 -2 -27 -13 -10 -17 -110 -51 -154 -51 -15 0 -36 16 -61 46 -21
        25 -54 52 -72 60 -50 21 -137 18 -186 -7 -32 -16 -54 -20 -95 -17 -64 6 -80
        17 -64 46 16 31 13 128 -5 165 -35 69 -48 76 -129 70 -65 -5 -74 -4 -83 14
        -19 34 -85 73 -125 73 -20 0 -62 11 -93 25 -57 25 -117 32 -143 16 -14 -9 -12
        -97 4 -135 8 -18 4 -19 -84 -13 -63 4 -99 11 -110 21 -16 14 -20 14 -51 -9
        l-34 -25 -26 25 c-34 33 -103 34 -155 4 -52 -31 -95 -20 -124 31 -18 32 -36
        47 -88 70 -36 17 -73 30 -82 30 -9 0 -28 -14 -42 -31 l-25 -31 -33 17 c-36 18
        -61 14 -61 -10 0 -8 -4 -15 -9 -15 -16 0 -100 102 -120 145 -29 62 -39 67
        -100 53 -46 -10 -56 -9 -94 9 -23 11 -73 33 -112 47 -115 44 -115 44 -115 69
        0 20 -4 23 -30 19 -24 -3 -31 -10 -36 -38 -5 -30 -9 -34 -28 -28 -27 8 -56 34
        -56 49 0 6 16 26 35 45 30 29 35 40 35 79 0 26 -4 51 -8 58 -4 7 -31 17 -60
        24 -45 9 -56 8 -79 -6 -21 -14 -27 -15 -34 -4 -5 8 -9 20 -9 27 0 15 -6 15
        -31 1 -17 -9 -26 -4 -57 30 -20 22 -49 44 -64 50 -23 9 -28 7 -33 -10 -4 -10
        -19 -26 -35 -34 -41 -21 -146 -45 -205 -46 -27 0 -59 -4 -70 -8 -12 -5 -30 -3
        -45 4 -14 7 -42 16 -62 20 -20 4 -44 16 -54 26 -9 10 -22 19 -28 19 -7 0 -30
        9 -51 20 -35 18 -41 19 -51 5 -14 -19 -24 -19 -24 -1 0 8 -11 28 -24 43 -25
        28 -25 28 -56 11 -18 -10 -48 -18 -69 -18 -20 0 -46 -7 -57 -15 -10 -8 -29
        -15 -41 -15 -28 0 -28 -4 -1 -43 l22 -30 -34 7 c-19 3 -41 13 -50 21 -9 8 -26
        15 -37 15 -11 0 -28 11 -37 25 -16 24 -21 25 -114 25 -94 0 -137 10 -113 24
        17 11 13 20 -17 38 -24 14 -48 16 -150 12 -68 -2 -139 -11 -163 -19 -23 -8
        -50 -15 -60 -15 -10 0 -35 -10 -56 -22 -37 -22 -46 -23 -257 -21 -203 2 -222
        3 -265 24 -32 16 -74 24 -141 29 -52 4 -108 12 -123 19 -16 6 -40 11 -54 11
        -15 0 -35 7 -44 16 -23 20 -70 31 -223 49 -136 16 -146 19 -146 38 0 8 -18 28
        -39 45 l-40 32 -20 -34 c-30 -48 -59 -65 -93 -54 -18 6 -38 6 -55 0 -20 -7
        -39 -5 -65 5 -33 13 -43 13 -85 0 -81 -25 -110 -28 -343 -28 -124 0 -241 -3
        -260 -7 -66 -15 -107 3 -192 84 -104 99 -154 118 -177 68 l-12 -27 -24 34
        c-14 18 -25 41 -25 51 0 10 -6 18 -14 18 -8 0 -21 3 -30 6 -10 4 -19 -5 -29
        -29z"
        />
        <path
          d="M10068 5183 c-5 -18 -15 -22 -74 -28 -38 -3 -78 -7 -89 -8 -23 -2
        -31 -39 -20 -94 l6 -34 55 7 c47 5 58 4 77 -14 33 -30 171 -102 195 -102 17 0
        22 6 22 28 0 48 -26 119 -59 161 -17 21 -31 46 -31 55 0 12 -9 16 -34 16 -25
        0 -36 5 -38 18 -3 14 -5 13 -10 -5z"
        />
        <path
          d="M9379 5060 c-24 -14 -169 -60 -189 -60 -9 0 -34 -13 -55 -30 -21 -16
        -46 -30 -57 -30 -11 0 -45 -24 -76 -53 -31 -30 -71 -59 -89 -66 -17 -7 -41
        -23 -54 -37 -12 -13 -30 -24 -39 -24 -9 0 -29 -13 -45 -30 -27 -28 -27 -30
        -12 -54 9 -13 32 -30 52 -37 23 -9 35 -19 35 -31 0 -34 28 -31 65 6 36 36 46
        40 100 36 39 -3 45 -13 45 -81 0 -76 24 -99 101 -99 59 0 75 -9 102 -52 22
        -35 36 -35 89 -2 55 35 74 57 105 124 14 30 40 75 58 100 75 105 94 181 55
        220 -24 24 -39 25 -66 5 -38 -29 -99 -9 -141 45 -10 12 -7 16 18 23 24 6 30
        13 27 30 -3 19 -9 22 -47 22 -55 0 -65 20 -14 28 21 4 48 14 61 22 35 25 9 48
        -29 25z"
        />
        <path
          d="M8083 4211 c-53 -9 -113 -49 -113 -77 0 -11 -10 -26 -23 -33 -24 -13
        -46 -71 -39 -104 3 -16 10 -18 47 -13 26 4 46 3 50 -4 3 -5 17 -10 30 -10 16
        0 25 7 29 24 7 30 94 121 124 130 17 6 22 15 22 40 0 55 -23 64 -127 47z"
        />
        <path
          d="M8057 3889 c-12 -7 -17 -23 -17 -55 0 -41 1 -44 20 -34 14 7 25 7 38
        -1 26 -16 93 -3 85 16 -3 8 -18 15 -33 15 -30 0 -60 22 -60 45 0 20 -13 25
        -33 14z"
        />
      </g>
    </svg>
  );
};
