import { Card } from "../../Components/Card/Card";
import { useTranslate } from "../../hooks/useTranslation";
import { experienceTextCardsData } from "./experienceTextCardsData";

export type TextCardsType = {
  headline: string;
  text: string;
};

export type TextCardsPropsType = {
  headline?: string;
  text?: string;
  TextCards?: TextCardsType[];
  className?: string;
};

export const TextCards = ({
  TextCards = experienceTextCardsData,
  headline,
  text,
  className,
}: TextCardsPropsType) => {
  const translate = useTranslate();

  return (
    <div className={`${className}`}>
      <h2 className="w-fit heading-2 md:text-left text-center">{headline}</h2>
      <div className="flex flex-col xl:flex-row gap-5 xl:gap-40 justify-between mt-20">
        <p className="heading-4 md:w-4/5 md:text-left text-center">{text}</p>
        <div className="flex flex-col md:grid md:grid-cols-12 md:gap-10">
          {TextCards.map((example) => {
            return (
              <Card className="flex flex-col gap-2 col-span-6 xl:col-span-4">
                <h3 className="heading-3">{translate(example.headline)}</h3>
                <p className="body-1">{translate(example.text)}</p>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
