import { useTranslate } from "../../hooks/useTranslation";
import { faqItemType } from "../../modules/FAQ/faqData";
import { AccordionItem } from "../AccordionItem/AccordionItem";

export const Accordion = ({
  items,
  className,
}: {
  items: faqItemType[];
  className?: string;
}) => {
  const tranlsate = useTranslate();

  return (
    <div className={`flex flex-wrap gap-5 ${className}`}>
      {items.map((item: any) => {
        return (
          <AccordionItem
            header={tranlsate(item.question)}
            text={tranlsate(item.answer)}
          />
        );
      })}
    </div>
  );
};
