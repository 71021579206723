import React, { useEffect, useState } from "react";
import "./StepForm.scss";
import { Button } from "../Components/Button/Button";
import {
  StepFormAnswerType,
  StepFormDataType,
  StepFormStepType,
} from "./StepFormTypes";
import { FormUserActionType, useRenderStepAnswer } from "./useRenderStepAnswer";
import { SafeTextRenderer } from "../Utils/SafeTextRenderer";

type FormStateAnswerType = {
  step_title: string;
  user_answer: string;
};

interface FormStateType {
  [key: string]: FormStateAnswerType;
}

export type StepFormPropsTypes = {
  className?: string;
  stepFormData: StepFormDataType;
  firstStep?: string;
  overline?: string;
};

export const StepForm = ({
  className,
  stepFormData,
  firstStep,
  overline,
}: StepFormPropsTypes) => {
  const [currentStep, setCurrentStep] = useState(
    firstStep || stepFormData.firstStep
  );
  const [formState, setFormState] = useState<FormStateType>({});
  const [fadeState, setFadeState] = useState<"in" | "out">("in");
  const [renderStep, setRenderStep] = useState(
    firstStep || stepFormData.firstStep
  );

  const currentStepData: StepFormStepType =
    stepFormData.steps.find((step: any) => step.id === renderStep) ||
    stepFormData.steps[1];

  useEffect(() => {
    if (currentStep !== renderStep) {
      setFadeState("out");
      const timer = setTimeout(() => {
        setRenderStep(currentStep);
        setFadeState("in");
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [currentStep, renderStep]);

  const handleUserAction = (
    action: FormUserActionType,
    answer: StepFormAnswerType
  ) => {
    const stepTitle = currentStepData.title;
    const userAnswer = action.actionData.label;
    setFormState((prevState) => ({
      ...prevState,
      [currentStepData.id]: {
        step_title: stepTitle,
        user_answer: userAnswer,
      },
    }));
    setCurrentStep(action.actionData.nextStepId);
  };

  return (
    <div
      className={`react-typed-container card-highlight pr-0 overflow-hidden shadow-smooth bg-white flex flex-col relative ${className}`}
    >
      {/* <div className="styled-indicator styled-indicator__top">
        <div className="styled-line styled-line--horizontal" />
        <div className="styled-line styled-line--vertical" />
      </div> */}
      <div className="relative p-6 md:p-10 pb-0 max-h-full min-h-full overflow-y-auto">
        <div
          className={`flex h-full flex-col justify-between fade-${fadeState}`}
        >
          <div className="body-2 text-dark step-form-text-container">
            {overline && <p className="text-blue text-base">{overline}</p>}
            <SafeTextRenderer className="text-dark" text={currentStepData.title} />
          </div>
          <div
            key={renderStep}
            className="mt-10 pb-10 step-form-answer-container"
          >
            {useRenderStepAnswer(currentStepData.answer, (action) =>
              handleUserAction(action, currentStepData.answer)
            )}
          </div>
        </div>
      </div>
      {/* <div className="styled-indicator styled-indicator__bottom">
        <div className="styled-line styled-line--vertical" />
        <div className="styled-line styled-line--horizontal" />
      </div> */}
    </div>
  );
};
