import { Accordion } from "../../Components/Accordion/Accordion";
import { useTranslate } from "../../hooks/useTranslation";
import { StepForm } from "../../StepForm/StepForm";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";
import { aiFaqData, faqDataType } from "./faqData";

type FAQPropsType = {
  className?: string;
  data?: faqDataType;
};

export const FAQ = ({ className, data = aiFaqData }: FAQPropsType) => {
  const tranlsate = useTranslate();
  return (
    <div className={`${className}`}>
      <h2 className="heading-2">
        {tranlsate(data.headline)}
      </h2>
      <div className="flex flex-col md:flex-row gap-10 mt-10 lg:mt-20" >
        <StepForm
          stepFormData={stepFormDataAI}
          className="h-[400px] md:h-[500px] bg-dark md:w-1/2"
          firstStep="questions-1"
        />
        <Accordion className="md:w-1/2" items={data.items} />
      </div>
    </div>
  );
};
