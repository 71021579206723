import { themeColors } from "../../Utils/tailwind/tailwindColorsConfig";

export const particleConfig: any = {
  background: {
    color: {
      value: themeColors.white,
    },
  },
  fpsLimit: 60,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      push: {
        quantity: 1,
      },
      attract: {
        duration: 10,
      },
    },
  },
  particles: {
    color: {
      value: themeColors.blue,
    },
    links: {
      color: themeColors.blue,
      distance: 145,
      enable: true,
      opacity: 0.2,
      width: 1,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: true,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 100,
    },
    opacity: {
      value: .2,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 1, max: 4 },
    },
  },
  detectRetina: true,
};
