import { StepFormStepType } from "../../StepFormTypes";
import {
  getStepFormOptionButton,
} from "./formDataAiUtils";

export const stepFormDataQuestions: StepFormStepType[] = [
  {
    id: "questions-1",
    title: "Let's try to solve your questions",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton(
            "How AI can help my business?",
            "questions-2"
          ),
          getStepFormOptionButton(
            "How does your AI consultation process works?",
            "questions-3"
          ),
          getStepFormOptionButton(
            "Can you help us find an expert or a team to hire?",
            "questions-4"
          ),
          getStepFormOptionButton(
            "What industries and AI technologies do you work with?",
            "questions-5"
          ),
          getStepFormOptionButton(
            "I have more questions, book a free discovery call",
            "bookCall-1"
          ),
        ],
      },
    },
  },
  {
    id: "questions-2",
    title:
      "AI has evolved far beyond just chatbots—it’s revolutionizing nearly every industry and business process. <br><br> Companies are using AI to automate operations and workflows, reduce errors, cut costs, etc. <br>From predictive analytics to smarter decision-making, AI offers endless possibilities.<br><br>But with so many potential applications, identifying the right fit for your business can be challenging. That’s where we come in—to help you navigate the AI landscape and unlock its full potential for your business.",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton(
            "Book my free AI discovery session",
            "bookCall-1"
          ),
          getStepFormOptionButton(
            "Show me real AI use cases in my industry.",
            "useCases-1"
          ),
          getStepFormOptionButton("Go back to questions", "questions-1"),
        ],
      },
    },
  },
  {
    id: "questions-3",
    title:
      "Our AI consultation process begins with a free discovery call to understand your needs and chart the best path forward.<br><br>After understanding your needs, we'll suggest a tailored solution for your specific case, for example strategy planning, software development and integration solutions, talent matching and more.",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton(
            "Book my free AI discovery session",
            "bookCall-1"
          ),
          getStepFormOptionButton("Go back to questions", "questions-1"),
        ],
      },
    },
  },
  {
    id: "questions-4",
    title:
      "Of course! We save you time and resources in the initial stages of recruitment by pre-screening and testing AI experts who match your specific requirements.<br><br>Our thorough vetting ensures that only the most qualified candidates, tailored to your unique needs, are presented to you.",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton("I need an expert", "findExpert-1"),
          getStepFormOptionButton(
            "I want to book the free discovery call first.",
            "bookCall-1"
          ),
          getStepFormOptionButton("Go back to questions", "questions-1"),
        ],
      },
    },
  },
  {
    id: "questions-5",
    title:
      "We serve all industries and sectors with various AI technologies.<br><br>Our vast network allows us to connect you with experts who have direct, relevant experience in your specific sector and are specialized in any AI field.",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton("Go back to questions", "questions-1"),
          getStepFormOptionButton(
            "I want to book the free discovery call",
            "bookCall-1"
          ),
        ],
      },
    },
  },
];
