import { StepFormStepType } from "../../StepFormTypes";
import {
  getOptionButtonFromArray,
  getStepFormOptionButton,
} from "./formDataAiUtils";

const businessSectors = [
  "Agriculture and Natural Resources",
  "Education / EdTech",
  "Arts, Media, and Entertainment",
  "Healthcare / Pharmaceuticals / biotech",
  "Retail / E-commerce",
  "Banking / Finance / FinTech",
  "Supply chain / logistics",
  "Travel",
  "Real estate / Construciton",
  "Manufacturing / Industrial",
  "Automotive",
  "Fashion",
  "IT / Technology",
];

const companyPositions = [
  "Founder / CEO",
  "Executive Management",
  "Marketing / Sales",
  "Data scientist / Engineer",
  "Consultant / Advisor",
  "HR",
  "Other",
];

const companyAIExperience = [
  "No experience",
  "Some experience",
  "Intermediate",
  "Advanced",
];

const aiConcerns = [
  "Cost of implementation",
  "Data privacy and security",
  "Lack of in-house expertise",
  "Unclear ROI or measurable benefits",
  "No concerns",
];

export const stepFormDataBookCall: StepFormStepType[] = [
  {
    id: "bookCall-1",
    title:
      "Let's book the free discovery call.<br> What is your position in the company ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(companyPositions, "bookCall-2")],
      },
    },
  },
  {
    id: "bookCall-2",
    title: "Which industry or sector is your business primarily involved in?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(businessSectors, "bookCall-3")],
      },
    },
  },
  {
    id: "bookCall-3",
    title: "How experienced is your team with AI technologies?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          ...getOptionButtonFromArray(companyAIExperience, "bookCall-4"),
        ],
      },
    },
  },
  {
    id: "bookCall-4",
    title: "Do you have any concerns about implementing AI in your business?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getOptionButtonFromArray(aiConcerns, "bookCall-5")],
      },
    },
  },
  {
    id: "bookCall-5",
    title:
      "We got it! Our team will analyze your information and reach out to set a meeting. How can we contact you ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormOptionButton("Preview version only", "TODO"),
        ],
      },
    },
  },
];
