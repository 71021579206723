import { stepFormDataFindExperts } from "./formData-ai-findExpert";
import { stepFormDataQuestions } from "./formData-ai-questions";
import { stepFormDataTalent } from "./formData-ai-talent";
import { stepFormDataUseCases } from "./formData-ai-useCase";
import { stepFormDataBookCall } from "./formData-bookCall";

export const stepFormAuSubforms = [
  ...stepFormDataBookCall,
  ...stepFormDataTalent,
  ...stepFormDataUseCases,
  ...stepFormDataFindExperts,
  ...stepFormDataQuestions,
];
